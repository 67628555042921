import React, {
    Component
} from 'react';
import './Upload.css';

import PageBuilder from '../../interfaces/PageBuilder.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Upload extends Component {

    fileObjArray = [];
    
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            images: [],
            submitEnabled: false,
            fileSelectEnabled: true,
            hotelName: null,
            hotelAddress: null,
            roomNumber:null,
            googleID: null,
            lat: null,
            lon: null,
            error: false,
            success: false,
            loading: false,
        }

        this.selectFiles = this.selectFiles.bind(this);
        this.handleRoomNumberChange = this.handleRoomNumberChange.bind(this);
        this.initMap = this.initMap.bind(this);
        this.submitImages = this.submitImages.bind(this);
        this.clearStoredData = this.clearStoredData.bind(this);
    }

    handleRoomNumberChange(event) {
        this.setState({ roomNumber: event.target.value });
    }      

    clearStoredData() {

        //~ window.location.reload(false);
        this.setState({
            images: [],
            fileSelectEnabled: true,
            submitEnabled: false,
            hotelName: null,
            hotelAddress: null,
            roomNumber:null,
            googleID: null,
            lat: null,
            lon: null,
            error: false,
            success: false,
        });

        this.fileObjArray = [];
        
        window.uploadMap.panTo({lat: window.defaultMapCenter[0], lng: window.defaultMapCenter[1]});
        window.uploadMap.setZoom(3);
        document.getElementById('pac-input').value = "";
        window.uploadMapInfoWindow.close();
        window.uploadMapMarker.setVisible(false);
        
    }

    removeSingleImage(index) {
        this.fileObjArray.splice(index, 1);
        this.setState({
            images: this.fileObjArray,
        });
    }

    async submitImages() {
        this.setState({ loading: true });
        console.log("clicked submit!");
        let formData = new FormData();

        let args = {
            hotelName: this.state.hotelName,
            lat: this.state.lat,
            lng: this.state.lon,
            room: this.state.roomNumber,
            google_id: this.state.googleID
        };

        console.log(args);
        args = JSON.stringify(args);
        console.log(args);
        
        let submitArray = [];

        for(let i = 0; i < this.fileObjArray.length; i++) {
            formData.append('images', this.fileObjArray[i]);
        }

        formData.append('args', args);

        const response = await fetch(`/api/hotels/submit_images`, {
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json',
            },
        }).catch((err) => {
            console.log(err)
        });

        if (response.status !== 200) {
            this.setState({
                    images: [],
                    error: true,
                    loading: false,
                    submitEnabled: false,
                    fileSelectEnabled: false
                });
            console.log(response.statusText);
        } else {
            this.setState({
                    images: [],
                    success: true,
                    loading: false,
                    submitEnabled: false,
                    fileSelectEnabled: false
                });
        }
    }

    mapImages(images) {
        return images.map(( elem, i) => {
            return (
                <div className= "image-wrapper">
                    <div className= "remove-image" onClick={() => { this.removeSingleImage(i) }}>X</div>
                    <img id={i} className="image-elem" alt="upload image entry" src={URL.createObjectURL(elem)} />
                </div>
            )
        });
    }

    selectFiles(files) {
        for (let i = 0; i < files.length; i++) {
            this.fileObjArray.push(files[i]);
        }

        this.setState({
            images: this.fileObjArray,
            submitEnabled: true
        });
    }

    loadScript(src) {
      return new Promise(function(resolve, reject) {
        let script = document.createElement('script');
        script.src = src;

        script.onload = () => resolve(script);
        script.onerror = () => reject(new Error(`Script load error for ${src}`));

        document.body.appendChild(script);
      });
    }

    initMap() {
        var map = new window.google.maps.Map(document.getElementById('upload-map'), {
            center: {lat: window.defaultMapCenter[0], lng: window.defaultMapCenter[1]},
            fullscreenControl: true,
            fullscreenControlOptions: {
                position: window.google.maps.ControlPosition.BOTTOM_LEFT
            },
            zoom: 3
        });

        window.uploadMap = map;

        var card = document.getElementById('pac-card');
        var input = document.getElementById('pac-input');

        map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(card);

        var options = {
            types: ['establishment'],
        };

        var autocomplete = new window.google.maps.places.Autocomplete(input, options);

        // Bind the map's bounds (viewport) property to the autocomplete object,
        // so that the autocomplete requests use the current map bounds for the
        // bounds option in the request.
        autocomplete.bindTo('bounds', map);

        // Set the data fields to return when the user selects a place.
        autocomplete.setFields(
            ['address_components', 'geometry', 'icon', 'name', 'place_id', 'formatted_address']);

        var infowindow = new window.google.maps.InfoWindow();
        window.uploadMapInfoWindow = infowindow;
        var infowindowContent = document.getElementById('infowindow-content');
        infowindow.setContent(infowindowContent);
        var marker = new window.google.maps.Marker({
            map: map,
            anchorPoint: new window.google.maps.Point(0, -29)
        });
        window.uploadMapMarker = marker;

        autocomplete.addListener('place_changed', () => {
            this.setState({
                hotelName: null,
                hotelAddress: null,
                roomNumber: "",
                googleID: null,
                lat: null,
                lon: null
            });
            
            infowindow.close();
            marker.setVisible(false);
            infowindowContent.style.display = 'none';
            var place = autocomplete.getPlace();
            if (!place.geometry) {
                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                window.alert("No details available for input: '" + place.name + "'");
                return;
            }
 
          // If the place has a geometry, then present it on a map.
          if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
          } else {
            map.setCenter(place.geometry.location);
            map.setZoom(12);  // Why 12? Because it looks good.
          }
          marker.setPosition(place.geometry.location);
          marker.setVisible(true);

          var address = '';
          var placeID = '';

          if (place.formatted_address) {
              address = place.formatted_address;  
          } else if (place.address_components) {
            address = [
                (place.address_components[0] && place.address_components[0].short_name || ''),
                (place.address_components[1] + "," && place.address_components[1].short_name + "," || ''),
                (place.address_components[2] + "," && place.address_components[3].short_name + ", " || ''),
                (place.address_components[5] && place.address_components[5].short_name || ''),
                (place.address_components[6] && place.address_components[6].short_name || '')
            ].join(' ');
          }

          if (place.place_id) {
              placeID = place.place_id;
          }
          
          document.getElementById('pac-input').value = "";

          this.setState({
            hotelName: place.name,
            hotelAddress: place.formatted_address,
            googleID: placeID,
            roomNumber: "",
            lat: place.geometry.location.lat(),
            lon: place.geometry.location.lng()
          });

          infowindowContent.children['place-icon'].src = place.icon;
          infowindowContent.children['place-name'].textContent = place.name;
          infowindowContent.children['place-address'].textContent = address;
          //~ infowindowContent.children['placeID'].textContent = placeID;

          infowindow.open(map, marker);
          infowindowContent.style.display = 'block';
        });

    }

    componentDidMount() {
        let fileSelector = document.getElementById('file-selector-input');
        fileSelector.addEventListener('change', (event) => {
            const fileList = event.target.files;
            this.selectFiles(fileList);
        });

        const dropArea = document.getElementById('drop-area');

        dropArea.addEventListener('dragover', (event) => {
          event.stopPropagation();
          event.preventDefault();
          event.dataTransfer.dropEffect = 'copy';
        });

        dropArea.addEventListener('drop', (event) => {
          event.stopPropagation();
          event.preventDefault();
          const fileList = event.dataTransfer.files;
          this.selectFiles(fileList);
        });

        let promise = this.loadScript(`https://maps.googleapis.com/maps/api/js?key=${window.localStorage.googleKey}&libraries=places`);

        promise.then(
          script => this.initMap(),
          error => console.log(`Error: ${error.message}`)
        );
    }

    render() {

        let helpText = (
                <div id="drop-area-help-text" className="helpMsg"> Drag and drop images here, or use the file selector below </div>
            )

        let errorText = (
            <div className="errorMsg" onClick={this.clearStoredData}> Something went wrong, unable to upload images. Click here to reset this form. </div>
        )

        let successText = (
            <div className="successMsg" onClick={this.clearStoredData}> Image/s Uploaded Successfully. Click here to reset this form. </div>
        )

        let selectedImages = (
            <div className= "placeholder-container">
                <div className = "placeholder-flexbox" >
                    <div className= "upload-placeholder-wrapper">
                        <img src="/img/placeholder_upload_wbg.svg" alt="placeholder"className="upload-placeholder-image" />
                    </div>
                    <div className= "upload-placeholder-wrapper">
                        <img src="/img/placeholder_upload_wbg.svg" alt="placeholder"className="upload-placeholder-image" />
                    </div>
                    <div className= "upload-placeholder-wrapper">
                        <img src="/img/placeholder_upload_wbg.svg" alt="placeholder"className="upload-placeholder-image" />
                    </div>
                </div>
                { this.state.error || this.state.success ? "" : helpText }
                { this.state.success ? successText : "" }
                { this.state.error ? errorText : "" }
            </div>
        )

        if(this.state.success === false && this.state.error === false) { 
            if (this.fileObjArray.length > 0){
                selectedImages = (
                    <div className= "upload-container">
                        {this.mapImages(this.fileObjArray)}
                    </div>
                )
            } 
        }

        if(this.state.loading) {
                selectedImages = (
                    <div className= "uploading-wrapper">
                        <FontAwesomeIcon icon="circle-notch" className="fa-spin uploading" />
                    </div>
                )
        }  

        let submitButton = (
            <div className="dumb-button-wrapper">
                <button className="btn btn-primary" id="submit-images-button" disabled={!this.state.submitEnabled} onClick={this.submitImages}>
                    <span>Submit Images</span>
                </button>
            </div>
        )
        
        return (
            <div className= "bulk-loader">
                <div className= "top-wrapper">
                    <div className= "places-search">
                        <div className="pac-card" id="pac-card">
                          <div id="pac-container">
                            <input id="pac-input" type="text"
                                placeholder="Search for an establishment"/>
                          </div>
                        </div>
                        <div id="upload-map"></div>
                        <div id="infowindow-content">
                          <img src="" width="16" height="16" id="place-icon"/>
                          <span id="place-name"  className="title"></span>
                          <br/>
                          <span id="place-address"></span>
                        </div>
                    </div>
                    <div className= "hotel-info-card">
                        <span className="hotel-info-label">Name: </span><span>{this.state.hotelName}</span>
                        <br />
                        <span className="hotel-info-label">Address: </span><span>{this.state.hotelAddress}</span>
                        <br/><br/>
                         <span className="hotel-info-label">Lat: </span><span>{this.state.lat}</span>
                         <br/>
                        <span className="hotel-info-label">Lon: </span><span>{this.state.lon}</span>
                        <br/><br/>
                        <span className="hotel-info-label">Room Number: </span>
                        <input id="roomNumberInput" className="hotel-info-input" type="text" value={this.state.roomNumber} onChange={this.handleRoomNumberChange} placeholder="Enter here..." />
                    </div>
                </div>
                <div className= "image-counter"> {`Images: ${this.state.images.length}`}</div>
                <div className= "drop-area" id= "drop-area">
                    { selectedImages }
                </div>
                <div id= "controls-wrapper">
                    <div id="file-selector-button">
                        <label>
                        <input type="file" id="file-selector-input" accept=".jpg, .jpeg, .png" disabled={!this.state.fileSelectEnabled} multiple />
                        <span class={`btn btn-primary ${this.state.fileSelectEnabled ? "" : "disabled-button"}`}>Select Files</span>
                        </label>
                    </div>
                    { submitButton }
                </div>
            </div>
        )
    }
}

class UploadBuilder extends PageBuilder {

    // @override
    async onPageLoad() {
        return;
    }

    // @override
    pageContent() {
        return (
            <Upload />
        );
    }

}

export {
    Upload,
    UploadBuilder
};
