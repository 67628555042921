import React, {
    Component
} from 'react';
import { Container, Row, Col } from 'react-grid-system';
import './ContactPage.css';

import PageBuilder from '../../interfaces/PageBuilder.js';

class ContactPage extends Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        return (
            <Container fluid className="contact-content">
                <Row justify="center" className="contact-row" style={{ margin: '0 auto' }}>
                    <Col xs={10}>
                        <h2>Contact Us</h2>
                        <p>If you are having technical difficulties using the TraffickCam application, need help uploading photos, or have feedback about the TraffickCam user experience, please email <a href="mailto:support@traffickcam.com?Subject=Support">support@traffickcam.com</a>.</p>
                    </Col>
                </Row>
            </Container>);
    }
}

class ContactPageBuilder extends PageBuilder {

    // @override
    async onPageLoad() {
        return;
    }

    // @override
    pageContent() {
        return (
            <ContactPage />
        );
    }

}

export {
    ContactPage,
    ContactPageBuilder
};
