import React, {useState} from 'react';

import './Checkbox.css';

/* Checkbox builds a button that is either true/false, and can be toggled.
 * 
 * Props:
 * onChange -- A function that is called when Checkbox is toggled.
 *             Takes in a boolean representing the new state of the
 *             checkbox. Returns true if the new state is okay, false
 *             otherwise. async ((bool): bool)
 * initCheck -- Is the Checkbox checked by default? (bool) */
function Checkbox(props) {
    // Destructure props so we can have default values
    const {
        onChange = async (_) => true,
        initCheck = false,
    } = props;

    // Make the Checkbox a stateful component
    const [checked, setChecked] = useState(initCheck);

    // Function to be called when the state of the checkbox updates.
    let boxOnChange = async () => {
        let newChecked = !checked;
        let ok = await onChange(newChecked);

        if (ok) {
            setChecked(newChecked);
        }
    };
    
    return (
        <input type='checkbox' checked={checked} 
                onChange={boxOnChange}/>
    )
}

export default Checkbox;