import React, { Component } from 'react';
import './SideBar.css';

import InfoBubble from '../InfoBubble/InfoBubble.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleMapReact from 'google-map-react';

class SideBar extends Component {

    constructor(props) {
        super(props);
        this.props = props;

        let mapCenter = this.props.coordinates;
        if (!(mapCenter)) {
            mapCenter = window.defaultMapCenter;
        }

        let passedCenter = this.props.coordinates;
        if (!(passedCenter)) {
            passedCenter = window.defaultMapCenter;
        }


        // we use this to make sure the user knows non locked changes will be lost
        window.unsavedChanges = !(this.props.locked);

        let mapZoom = this.props.mapZoom;

        if (!(mapZoom)) {
            // this is as far as google maps allows for zoom out
            mapZoom = 3;
        }

        this.mapRef = React.createRef();

        let uploadedImageText = `
            The "Uploaded Image" is the image that is used for the image search.
            The results from the image search will be the images that our
            algorithms believe to be the nearest matching hotel rooms to the
            room in which the searched image was taken.`;

        let locationText = `
            The "Location" parameter allows you to define geographics bounds on
            which images are returned in your search. The bounds are defined by
            the region of the map and provide a filtering functionality to your
            search. The images returned from the search will only be images
            taken within the bounds specified by the map below.`;

        let keywordText = `
            The "Keyword" parameter allows you to define all words that you
            wish and not wish to focus on in your search. For example, if
            you include the keyword "Hilton" in your keyword parameter, you
            would get results that were mainly Hilton hotels. However, if
            you include "NOT Hilton" in your keyword search, Hilton hotels
            would be omitted from the search results.`;

        this.state = {
            canvasStyle: {
                display: 'block',
                maxHeight: '130px',
                margin: '0 auto',
                marginTop: '20px',
                width: '130px',
            },
            keywords: this.props.keywords,
            location: this.props.location,
            mapCenter: mapCenter,
            mapBounds: null,
            mapZoom: mapZoom,
            mapsKey: window.localStorage.googleKey,
            unsavedChanges: false,
            keywordsChanged: false,
            locationChanged: false,
            advancedToggled: false,
            updated: false,
            mapQuerySet: false,
            mapObj: null,
            mapLoaded: false,
            passedCenter: passedCenter,
            uploadedImageText: uploadedImageText,
            locationText: locationText,
            keywordText: keywordText
        }


    }

    componentDidUpdate() {

        if (this.props.locked && (this.props.keywords || this.props.keywords || this.props.keywords || this.props.mapZoom) && !(this.state.updated)) {
            let keywords = this.props.keywords ? this.props.keywords : this.state.keywords;
            let location = this.props.location ? this.props.location : this.state.location;
            let mapCenter = this.props.coordinates ? this.props.coordinates : this.state.coordinates;
            let mapZoom = this.props.mapZoom ? this.props.mapZoom : this.state.mapZoom;



            this.setState({
                keywords: keywords,
                location: location,
                mapCenter: mapCenter,
                mapZoom: mapZoom,
                updated: true
            });

            this.toggleAdvancedLoading('hide');

            this.loadMap(mapCenter, mapZoom);
        }

    }
    updateMapState(map) {
        let boundsObj = map.getBounds();

        if (!(boundsObj)) {
            return;
        }


        let bounds = {
            'ne': [parseFloat(boundsObj.getNorthEast().lat()), parseFloat(boundsObj.getNorthEast().lng())],
            'sw': [parseFloat(boundsObj.getSouthWest().lat()), parseFloat(boundsObj.getSouthWest().lng())],
        }

        let zoom = map.getZoom();

        let centerObj = map.getCenter();
        let center = [parseFloat(centerObj.lat().toFixed(4)), parseFloat(centerObj.lng().toFixed(4))];

        let unsavedChanges = this.state.unsavedChanges;

        if (!(Math.abs(center[0] - this.state.passedCenter[0]) < .1 && Math.abs(center[1] - this.state.passedCenter[1]) < .1)) {
            unsavedChanges = true;
        }

        if (this.state.mapZoom != zoom) {
            unsavedChanges = true;
        }


        this.setState({
            mapBounds: bounds,
            mapZoom: zoom,
            mapCenter: center,
            unsavedChanges: unsavedChanges
        })
    }

    loadMap(mapCenter = null, mapZoom = null) {
        var map = null;

        let zoomControl = !this.props.locked;

        // because sometimes setState is slow and we need to force variables
        if (!(mapCenter) && !(mapZoom)) {
            map = new window.google.maps.Map(document.getElementById('map'), {
                center: { lat: parseFloat(this.state.mapCenter[0]), lng: parseFloat(this.state.mapCenter[1]) },
                zoom: this.state.mapZoom,
                fullscreenControl: false,
                streetViewControl: false,
                zoomControl: zoomControl,
                minZoom: 1,
            });
        } else {
            map = new window.google.maps.Map(document.getElementById('map'), {
                center: { lat: mapCenter[0], lng: mapCenter[1] },
                zoom: mapZoom,
                fullscreenControl: false,
                streetViewControl: false,
                zoomControl: zoomControl,
                minZoom: 1,
            });
        }

        window.map = map;


        window.google.maps.event.addListener(map, 'bounds_changed', (e) => {
            this.updateMapState(map);
        });

        window.google.maps.event.addListener(map, 'zoom_changed', (e) => {
            this.updateMapState(map);
        });


    }

    componentDidMount() {
        if (!(this.props.locked)) {
            this.loadMap();
        } else {
            this.toggleAdvancedLoading('show');
        }

    }

    toggleAdvancedLoading(action) {
        if (action === 'show') {
            document.getElementById('advanced-options-loading-wrapper').classList.add('display');
            document.getElementById('advanced-options-inner-wrapper').classList.add('hide');

        } else if (action === 'hide') {
            document.getElementById('advanced-options-loading-wrapper').classList.remove('display');
            document.getElementById('advanced-options-inner-wrapper').classList.remove('hide');
        }

    }



    render() {

        let mapUrlArray = (url) => {
            let centerCoordsStr = url.split('?')[1].split('&')[0];

            let centerCoords = centerCoordsStr.split('=')[1].split(',');
            centerCoords = centerCoords.map((e) => parseFloat(e));

            return centerCoords;
        }

        let imgCanvas;
        if (this.props.imgUrl) {

            imgCanvas = (
                <div>
                    <div className= "uploaded-image-box">
                        <span>Uploaded image</span>
                        <InfoBubble direction="bottom">{this.state.uploadedImageText}</InfoBubble>
                        <span>
                            <FontAwesomeIcon icon="circle-notch" id="load-sidebar"
                                className="fa-spin load-sidebar show" />
                            <div className="uploaded-entry-wrapper hide" id="uploaded-entry-wrapper">    
                                <img src={this.props.imgUrl} alt="" className="uploaded-entry"
                                    id="uploaded-entry-sb"
                                    onLoad={() => {
                                        let loader = document.getElementById('load-sidebar');
                                        let sidebarImage = document.getElementById('uploaded-entry-sb');
                                        let sidebarImageWrapper = document.getElementById('uploaded-entry-wrapper');

                                        sidebarImageWrapper.classList.toggle('hide');
                                        loader.classList.toggle('show');
                                        sidebarImage.classList.toggle('show');
                                    }}
                                    style={this.props.imgStyles} />
                            </div>
                        </span>
                    </div>
                    <hr className="image-sb-br" />
                </div>
            );
                
            if (this.props.imgUrl.startsWith('data')) {
                imgCanvas = (
                    <div>
                        <span>Uploaded image</span>
                        <InfoBubble direction="bottom">{this.state.uploadedImageText}</InfoBubble>
                        <span>
                            <div className="uploaded-entry-wrapper">
                                <img src={this.props.imgUrl} alt="" className="uploaded-entry show"
                                    id="uploaded-entry-sb"
                                    style={this.props.imgStyles} />
                            </div>
                        </span>
                        <hr className="image-sb-br" />
                    </div>
                );
            }

            if (this.props.imgContent) {
                imgCanvas = this.props.imgContent;
            }
        } else {
            imgCanvas = ""

            //~ (
                //~ <FontAwesomeIcon icon="circle-notch"
                    //~ className="fa-spin load-sidebar show" />
            //~ );
        }


        let editToggle = '';

        let resetSearch = '';

        function handleKeywordChange() {
            let updateSearchSelector = document.getElementById('update-search-text');

            // if we are on the results page
            if (updateSearchSelector && this.props.resultsPage) {
                updateSearchSelector.setAttribute('style', 'display: inline-block');
                this.setState({ unsavedChanges: true, keywordsChanged: true });
            } else {
                this.setState({ keywordsChanged: true });
            }
        }

        function handleLocationChange() {
            let updateSearchSelector = document.getElementById('update-search-text');

            // if we are on the results page
            if (updateSearchSelector && this.props.resultsPage) {
                updateSearchSelector.setAttribute('style', 'display: inline-block');
            }
            this.setState({ locationChanged: true });


        }


        let locationInput = (
            <input type="text" className="keywords-input"
                id="location-input" placeholder="Enter Location"
                onChange={handleLocationChange.bind(this)}
            />
        );

        if (this.state.location && !(this.state.locationChanged)) {
            locationInput = (
                <input type="text" className="keywords-input"
                    id="location-input" placeholder="Enter Location"
                    value={this.state.location}
                    onChange={handleLocationChange.bind(this)}
                />
            );
        }

        let updateMap;
        // if the location input has changed, show update map button
        if (this.state.locationChanged) {
            updateMap = (
                <div className="update-map-btn-wrapper">
                    <div className="update-map-btn"
                        onClick={(e) => {
                            let locationInput = document.getElementById('location-input');

                            document.getElementById('update-map-loading').classList.toggle('show');
                            console.dir(locationInput);
                            this.updateMap(locationInput.value);
                        }}>
                        Update Map
                    </div>
                    <FontAwesomeIcon icon="circle-notch" id="update-map-loading"
                        className="update-map-loading fa-spin" />
                </div>
            );
        }

        let keywordInput = (
            <input type="text" className="keywords-input"
                id="keywords-input" placeholder="Enter Search Terms"
                onChange={handleKeywordChange.bind(this)}
            />
        );

        if (this.state.keywords && !(this.state.keywordsChanged)) {
            keywordInput = (
                <input type="text" className="keywords-input"
                    id="keywords-input" placeholder="Enter Search Terms"
                    value={this.state.keywords}
                    onChange={handleKeywordChange.bind(this)}
                />
            );
        }

        //alert(this.props.locked);

        if (this.props.locked) {
            locationInput = (
                <input type="text" className="keywords-input"
                    id="location-input" placeholder="Enter Location"
                    value={this.state.location ? this.state.location : 'No location query set'}
                    disabled
                />
            );

            keywordInput = (
                <input type="text" className="keywords-input"
                    id="keywords-input" placeholder="Enter Search Terms"
                    value={this.props.keywords ? this.props.keywords : 'No keyword query set'}
                    disabled
                />
            );
        }

        if (this.props.resetSearch) {
            resetSearch = (
                <div className="reset-search" onClick={this.props.resetSearchFunc}>
                    <FontAwesomeIcon icon="redo-alt" /> Reset Search
                </div>
            );
        }

        let updateSearchBtn = '';

        if (this.state.unsavedChanges && this.props.resultsPage && !(this.props.locked)) {
            updateSearchBtn = (
                <button className="btn-primary"
                    onClick={this.updateSearch.bind(this)}>
                    Update Search
                </button>);
        }

        //~ if (this.props.startSearch) {
            //~ updateSearchBtn = (
                //~ <button className= "btn-primary"
                    //~ onClick={ () => console.log('Click!')}>
                    //~ Search Without Image
                //~ </button>);
        //~ }

        let advancedClass = 'display';

        let lockMapClass = this.props.locked ? 'disabled' : '';

        return (
            <div className="sidebar" id="sidebar">
                {resetSearch}
                <div className="uploaded-image option unselectable">
                    {imgCanvas}
                </div>
                {editToggle}
                <div id="advanced-options" className={advancedClass}>

                    <span id="advanced-options-inner-wrapper">
                        <div id="set-keywords" className="option">
                            Search Keywords
                        <InfoBubble direction="right" text={this.state.keywordText} />
                            {keywordInput}
                        </div>
                        <div id="set-location" className="option">
                            Search Location
                        <InfoBubble direction="right" text={this.state.locationText} />
                            {locationInput}
                            {updateMap}
                            {/*
                                <div className="map-coords">
                                    Center of Map:
                                    <span id="map-coords">
                                            [{this.state.mapCenter[0]}, {this.state.mapCenter[1]}]
                                    </span>
                                </div>
                            */}
                            <div className={`set-location-inner ${lockMapClass}`} style={{ height: `200px`, width: `100%` }}>
                                <div className="map-contents" id="map"></div>
                            </div>
                        </div>
                        {updateSearchBtn}
                    </span>
                    <span id="advanced-options-loading-wrapper" className="advanced-options-loading-wrapper">
                        <FontAwesomeIcon className="advanced-options-loading fa-spin"
                            icon="circle-notch" />

                        <h2 className="advanced-options-loading-text">
                            Loading Advanced Options
                        </h2>
                    </span>
                </div>

            </div>
        );
    }

    async updateMap(query) {
        let urlFormattedQuery = encodeURI(query.split(' ').join('+'));

        let passed = true;
        const searchResponse = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${urlFormattedQuery}&key=${this.state.mapsKey}`, {
            method: 'GET'
        }).catch(() => {
            passed = false;
        });

        if (passed) {
            const retrievedSearchInfo = await searchResponse.json();

            if (retrievedSearchInfo.status === 'OK') {
                let newCoordsObj = retrievedSearchInfo.results[0].geometry.location;
                let newCoordsArray = [newCoordsObj.lat, newCoordsObj.lng];


                this.setState({
                    mapCenter: newCoordsArray,
                    location: query,
                    mapZoom: 9,
                    mapQuerySet: true,
                    locationChanged: false,
                    unsavedChanges: true
                });

                this.loadMap();
            } else {
                if (retrievedSearchInfo.status === 'ZERO_RESULTS') {
                    alert(`Unable to find location "${query}"`);
                    this.setState({ locationChanged: false });
                }
                passed = false;
            }

        }

        if (!(passed)) {
            alert('Unable to update map');
            this.setState({ locationChanged: false });
        }
    }

    updateSearch() {
        document.getElementById('update-search-text').setAttribute('style', 'display: none');

        let keywords = document.getElementById('keywords-input').value;
        let location = document.getElementById('location-input').value;


        //we need to flip this toggle to keep the loader/image synced up
        let loader = document.getElementById('load-sidebar');
        let sidebarImage = document.getElementById('uploaded-entry-sb');
        loader.classList.toggle('show');
        sidebarImage.classList.toggle('show');

        let updateSearchObj = {
            keywords: keywords,
            location: location,
            coordinates: this.state.mapCenter,
            bounds: this.state.mapBounds,
            zoom: this.state.mapZoom,
            //~ imgUrl: this.props.imgUrl,
            //~ sidebarImgUrl: this.props.imgUrl,
            imgValue: null
        }

        this.props.updateSearch(updateSearchObj);

        this.setState({
            unsavedChanges: false,
            keywordsChanged: false,
            locationChanged: false,
            keywords: keywords,
            coordinates: this.state.mapCenter,
            location: location,
            advancedToggled: true
        });
    }

    toggleAdvanced() {
        document.getElementById('advanced-options').classList.toggle('display');
        document.getElementById('advanced-chevron').classList.toggle('rotate');
    }

    hideMapFeatures() {
        //let pc = document.getElementsByClassName('place-card');
    }

}

export default SideBar;
