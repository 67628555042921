import React, { Component } from 'react';
import './OptionBar.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class OptionBar extends Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            // searchID: this.props.searchID,
            // imgURL: this.props.imgURL,
            // sidebarImgUrl: this.props.sidebarImgUrl
        }
    }

    render() {
        let downloadLink = '';
        if (this.props.download) {
            downloadLink = (
                <span className="report-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={this.props.rep}
                >
                    Generate Search Report
                    <FontAwesomeIcon icon="download"
                        className="report-dl-icon" />
                </span>);
        }

        return (
            <div className="optionbar">
                <ul id="optionButtons">
                    <li id="update-search-text">
                        Search parameters have been changed.
                        Update search for new results
                    </li>
                    {
                        this.props.locked ?
                            <li id="locked-search-text">
                                <FontAwesomeIcon icon="lock"
                                    className="locked-search-icon" />
                                Search is locked
                            </li>
                            :
                            null
                    }
                </ul>
                {downloadLink}
            </div>
        )
    }
}

export default OptionBar;
