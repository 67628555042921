import React, {
    Component
} from 'react';
import './HomePage.css';

import PageBuilder from '../../interfaces/PageBuilder.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class HomePage extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                {/* Preload images that will be used for login button */}
                <link rel='preload' as='image'
                    href='/img/btn_google_signin_dark_normal_web@2x.png'/>
                <link rel='preload' as='image'
                    href='/img/btn_google_signin_dark_focus_web@2x.png'/>
                <link rel='preload' as='image'
                    href='/img/btn_google_signin_dark_pressed_web@2x.png'/>

                <link rel='preload' as='image'
                    href='/img/ms-symbollockup_signin_dark.png'/>
                <link rel='preload' as='image'
                    href='/img/ms-symbollockup_signin_light.png'/>

                <div className="home-page-content">
                    <div className="home-page-wrapper">
                        <div className="home-page-header">
                            <h1>Sign into TraffickCam</h1>
                        </div>
                        <div className="home-page-interaction">
                            <div className="login-wrapper">
                                <button className="login-btn"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open("/login_google", "_self");
                                    }}
                                />

                                <button className="login-ms-btn"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open("/login_microsoft", "_self");
                                    }}
                                />

                                <div className="login-error-msg">
                                    <div id="invalid-creds">
                                        <FontAwesomeIcon icon="exclamation-circle" className="error-icon" />
                                        Invalid credentials provided
                                    </div>
                                    <div id="server-unavailable">
                                        <FontAwesomeIcon icon="exclamation-triangle" className="warning-icon" />
                                        Unable to reach server
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>);
    }
}

class HomePageBuilder extends PageBuilder {

    // @override
    async onPageLoad() {
        return;
    }

    // @override
    pageContent() {
        return (
            <HomePage />
        );
    }

}

export {
    HomePage,
    HomePageBuilder
};
