/* index.js
 * Description: The file in which all page routing occurs
 */
import React from 'react';
import ReactDOM from 'react-dom';

import Url from 'domurl';
import Page from './Page';
import './index.css';

// import of all page builders

import {
    ContactPageBuilder
} from './pages/ContactPage/ContactPage.js';

import {
    ErrorPageBuilder
} from './pages/ErrorPage/ErrorPage.js';

import {
    HomePageBuilder
} from './pages/HomePage/HomePage.js';

import {
    ProfilePageBuilder
} from './pages/ProfilePage/ProfilePage.js';

import {
    SearchPageBuilder
} from './pages/SearchPage/SearchPage.js';

import {
    TutorialPageBuilder
} from './pages/TutorialPage/TutorialPage.js';

import {
    HotelPageBuilder
} from './pages/HotelPage/HotelPage.js';

import {
    AdminPageBuilder
} from './pages/AdminPage/AdminPage.js';

import {
    ConfigPageBuilder
} from './pages/ConfigPage/ConfigPage.js';

import {
    HistoryPageBuilder
} from './pages/HistoryPage/HistoryPage';

import {
    UploadBuilder
} from './pages/Upload/Upload.js';

import {
    ChangePasswordPageBuilder
} from './pages/ChangePasswordPage/ChangePasswordPage';

import {
    AdminLoginPageBuilder
} from './pages/AdminLoginPage/AdminLoginPage';


import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faHome, faChevronDown, faQuestionCircle, faChevronLeft, faChevronRight,
    faArrowLeft, faArrowRight, faRedoAlt, faDownload, faUndoAlt, faSyncAlt,
    faPen, faCropAlt, faSpinner, faEllipsisH, faCircleNotch, faGlobeAmericas,
    faExpand, faBars, faSearch, faTimes, faLock, faBuilding, faExclamationCircle,
    faExclamationTriangle, faEyeSlash, faClone, faEye, faSignOutAlt, faHistory,
    faCheck, faFlag, faSlidersH, faBorderNone, faBorderAll, faCut, faSearchPlus
} from '@fortawesome/free-solid-svg-icons';
import { ResultsPageBuilder } from './pages/ResultsPage/ResultsPage';

import { loadConfig } from './config.js';

library.add(faHome);
library.add(faChevronDown);
library.add(faQuestionCircle);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faArrowLeft);
library.add(faArrowRight);
library.add(faRedoAlt);
library.add(faDownload);
library.add(faUndoAlt)
library.add(faSyncAlt);
library.add(faPen);
library.add(faCropAlt);
library.add(faSpinner);
library.add(faEllipsisH);
library.add(faCircleNotch);
library.add(faGlobeAmericas);
library.add(faExpand);
library.add(faBars);
library.add(faSearch);
library.add(faTimes);
library.add(faLock);
library.add(faBuilding);
library.add(faExclamationCircle);
library.add(faExclamationTriangle);
library.add(faEyeSlash);
library.add(faEye);
library.add(faClone);
library.add(faSignOutAlt);
library.add(faHistory);
library.add(faCheck);
library.add(faFlag);
library.add(faSlidersH);
library.add(faBorderNone);
library.add(faBorderAll);
library.add(faCut);
library.add(faSearchPlus);

var currentUrl = null;

async function main() {

    // URL Mapping for PageLabels
    var url = new Url();

    var path = url.path.replace("/", "");
    var components = path.split("/");

    if (components && components[0] === 'reset') {
        window.history.replaceState({}, '', '');
    }

    // Bootstrapping for CVPageBuilder
    var builder = getBuilder(url);
    var result = await builder.onPageLoad(url);

    // If there was an error in the onPageLoad functiom, show an error page
    if (result === false) {
        builder = new ErrorPageBuilder();
    }

    ReactDOM.render(
        <Page
            content={
                builder.pageContent()
            }
        />,
        document.getElementById("root")
    )

    window.onbeforeunload = function () {
        if (builder.onPageClose !== undefined) {
            builder.onPageClose();
        }
    };
}

// Returns a PageBuilder object, based on the current URL of the page
function getBuilder() {
    //alert("new builder")
    var url = new Url();
    var path = url.path.replace("/", "");
    var components = path.split("/");

    // components[0] contains the destination of the URL, use this to build routes
    switch (components[0]) {
        case "":
            return new HomePageBuilder();
        case "contact":
            return new ContactPageBuilder();
        case "profile":
            return new ProfilePageBuilder();
        case "search":
            return new SearchPageBuilder();
        case "results":

            return new ResultsPageBuilder({
                urlAccessed: true,
                searchID: components[1]
            });
        case "hotel":
            return new HotelPageBuilder({
                hotelID: components[1],
            });
        case "tutorial":
            return new TutorialPageBuilder();

        case "admin":
            return new AdminPageBuilder();
        case "config":
            return new ConfigPageBuilder();
        case "roles":
            return new RolesPageBuilder();
        case "review":
            return new ReviewPageBuilder();
        case "reviewed":
            return new ReviewedPageBuilder();
        case "cleanup":
            return new HotelCleanupPageBuilder();
        case "history":
            return new HistoryPageBuilder();
        case "change_password":
            return new ChangePasswordPageBuilder();
        case "upload":
            return new UploadBuilder();
        case "admin_login":
            return new AdminLoginPageBuilder();
        case "error":
            let error_code = url.query['error'];
            return new ErrorPageBuilder({ status: error_code });
        default:
            return new ErrorPageBuilder({ status: 404 });
    }
}

// I know this is terrible but it works for going back from results
window.addEventListener('popstate', e => {
    var path = String(window.location);


    var components = path.split("/");

    console.log(components)

    if (components[3] === '' || components[3] === 'search') {
        // this is here to trick the linter
        let currentLocation = window.location;
        window.location = currentLocation;
    } else if (components[3] === 'results') {
        let currentLocation = window.location;
        window.location = currentLocation;
    }

});

// load config variables from config.js
loadConfig();

main();
