import React, {
    Component
} from 'react';
import './ChangePasswordPage.css';

import PageBuilder from '../../interfaces/PageBuilder.js';

import InfoBubble from '../../components/InfoBubble/InfoBubble.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ChangePasswordPage extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }
    
    componentWillMount() {
        // // 99 % certain we don't need this login checking code as the Flask app
        // // already handles login sessions.
        // let params = new URL(window.location).searchParams;
        // let token = params.get('token');
        // if (token && window.localStorage.apiKey) {
        //     this.state.anotherUserLoggedInError = true;
        // } else {
        //     if (token) {
        //         this.state.setNewPassword = true;
        //     }
        //     if (!(token || window.localStorage.apiKey)) {
        //         window.location = '/' // redirect to login if not (logged in or one-time token exists)
        //     }
        // }
    }
    
    componentDidMount() {
        if(this.state.anotherUserLoggedInError) {
            this.showError(`Another user is logged in. Logging out in 5 seconds...`)
            setTimeout(() => {
                window.location.replace('/log_out')
            }, 5000);
        }
    }
    
    render() {
    
        let inputOldPassword = (
            <input className="change-password-input" type="password" id="old-pass" placeholder="Old Password" />
        );
        
        // TODO: add a tooltip that describes password policy
        return (
            <div className="change-password-content">
                <div className="change-password-content-inner">
                    <div className="change-password-header">
                        <h1>
                            Change password
                        </h1>
                        <InfoBubble>
                            Passwords must be at least {window.minimumPasswordLength} characters long
                        </InfoBubble>
                        <br></br>
                        <h3>Use the inputs below to change your password</h3>
                    </div>
                    <div className="change-password-body">
                        {this.state.setNewPassword ? '' : inputOldPassword}
                        <input className="change-password-input" type="password"
                            id="new-pass" placeholder="New Password" />
                        <input className="change-password-input" type="password"
                            id="new-pass-confirm" placeholder="Confirm New Password" />
                        <button className="btn-primary confirm-pass"
                            onClick={() => {
                                this.changePassword();
                            }}>
                            Change Password
                            <FontAwesomeIcon icon="circle-notch" id="confirm-loading" className="fa-spin" />
                        </button>
                    </div>
                    <div className="change-password-footer">
                        <div className="change-password-warning" id="change-password-warning">
                            <FontAwesomeIcon icon="exclamation-circle" />
                            <span id="warning-text">New passwords don't match</span>
                        </div>
                        <div className="change-password-success" id="change-password-success">
                            <FontAwesomeIcon icon="check" />
                            <span id="success-text">Successfully updated password</span>
                        </div>
                    </div>
                </div>
            </div>);
    }

    showLoading() {
        document.getElementById('confirm-loading').style.display = 'inline-block';
    }

    hideLoading() {
        document.getElementById('confirm-loading').style.display = 'none';
    }

    showError(errorMsg) {
        this.hideSuccess();
        document.getElementById('warning-text').innerText = errorMsg;
        document.getElementById('change-password-warning').style.display = 'block';
    }

    hideError() {
        document.getElementById('change-password-warning').style.display = 'none';
    }

    showSuccess(successMsg) {
        this.hideError();
        document.getElementById('success-text').innerText = successMsg;
        document.getElementById('change-password-success').style.display = 'block';
    }

    hideSuccess() {
        document.getElementById('change-password-success').style.display = 'none';
    }

    async changePassword() {
        // get input values
        let oldPassword;
        if (this.state.setNewPassword) oldPassword = '';
        else oldPassword = document.getElementById('old-pass').value;
            
        let newPassword = document.getElementById('new-pass').value;
        let newPasswordConfirm = document.getElementById('new-pass-confirm').value;

        // make sure all inputs are filled

        // if no value was given to old password input
        if (!this.state.setNewPassword && !(oldPassword)) {
            this.showError('Your old password is required');
            return;
        }

        // if no value was given to new password input
        if (!(newPassword)) {
            this.showError('Your new password is required');
            return;
        }

        // if no value was given to new password confirmation input
        if (!(newPasswordConfirm)) {
            this.showError('Your new password confirmation is required');
            return;
        }

        // make sure the new password and new password confirmation match
        if (newPassword !== newPasswordConfirm) {
            this.showError('Your new passwords do not match');
            return;
        }

        // make sure the new password isn't the same as the old password
        if (newPassword === oldPassword) {
            this.showError('Your new password cannot be the same as your old password');
            return;
        }

        // enforce length policy
        if (newPassword.length < window.minimumPasswordLength) {
            this.showError(`Your password must be at least ${window.minimumPasswordLength} characters long`);
            return;
        }

        // make request to API to initiate password change
        this.hideError();
        this.showLoading();

        let passed = true;
        
        let params = new URL(window.location).searchParams;
        let token = params.get('token');
        let apiKey = token || window.localStorage.apiKey;
        const searchResponse = await fetch(`${window.apiUrl}/change_password?key=${apiKey}`, {
            method: 'POST',
            body: JSON.stringify({
                old_password: oldPassword,
                new_password: newPassword,
                using_one_time_token: !!token
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).catch(() => {
            passed = false;
        });

        if (passed) {

            const statusCode = searchResponse.status;

            if (statusCode === 200) {
                // show success message
                if(!!token) {
                    this.showSuccess('Successfully updated password. Redirecting to the login page...');
                    setTimeout(() => {
                        window.location = '/' // redirect to the main page (for login)
                    }, 3000);
                } else{
                    this.showSuccess('Successfully updated password');
                }

                
                // clear all inputs
                if (!this.state.setNewPassword) {
                    document.getElementById('old-pass').value = '';
                }
                document.getElementById('new-pass').value = '';
                document.getElementById('new-pass-confirm').value = '';
                if (this.state.setNewPassword) {
                    let respData = await searchResponse.json();
                    window.localStorage.setItem('apiKey', respData.new_key);
                    setTimeout(() => {
                        window.location = 'search' // redirect to the main page
                    }, 5000);
                }
            } else if (statusCode === 403) {
                // old password is invalid
                this.showError('Invalid old password provided');
            } else if (statusCode === 422) {
                // insufficient length of password
                this.showError('New password is too short')
            } else {
                this.showError('Unable to update password');
            }
            this.hideLoading();
        } else {
            this.showError('Unable to update password');
            this.hideLoading();
        }

    }
}

class ChangePasswordPageBuilder extends PageBuilder {

    // @override
    async onPageLoad() {
        return;
    }

    // @override
    pageContent() {
        return (
            <ChangePasswordPage />
        );
    }

}

export {
    ChangePasswordPage,
    ChangePasswordPageBuilder
};