import React, {useState} from 'react';

import './Inputbox.css';

/* Inputbox is a borderless text input area.
 *
 * Props:
 * onChange -- A function that is called when the user presses
 *             enter. Takes in a string representing the new value 
 *             of the Inputbox. (async (string): void)
 * initValue -- The initial text to store in the entry box. */
function Inputbox(props) {
    // Destructure props so we can have default values
    const {
        onChange = async(_) => {},
        initValue = '',
    } = props;

    // Make Inputbox a stateful component
    const [value, setValue] = useState(initValue);

    // Function to be called when the state of the Inputbox updates.
    let boxOnChange = (event) => setValue(event.target.value);

    // Function to be called when the user presses a key
    let onKeyDown = async (event) => {
        // If key pressed was enter
        if (event.code === 'Enter') {
            await onChange(value);
        }
    };

    return (
        <input type='text' value={value}
                style={{'minWidth': value.length/1.25 + 'ch'}} // resize input
                onChange={boxOnChange}
                onKeyDown={onKeyDown}
                className='inputbox'/>
    )
}

export default Inputbox;