import React, {
    Component
} from 'react';
import './ConfigPage.css';

import PageBuilder from '../../interfaces/PageBuilder.js';


class ConfigPage extends Component {

    constructor(props) {
        super(props);
    }

    // attach key press when component mounts
    componentDidMount() {
        window.onload = function() {
			var win = document.getElementsByTagName('iframe')[0].contentWindow;
			win.postMessage(JSON.stringify({key: 'apiKey', data: 'API Keys are no longer visible'}), "*");
		};
    }

    render() {
        return (
            <iframe src="https://sapper.dev.imagingforgood.com/admintools" frameborder="0" width="100%" height="1000px"></iframe>
        )
    }

}

class ConfigPageBuilder extends PageBuilder {

    // @override
    async onPageLoad() {
        return;
    }

    // @override
    pageContent() {
        return (
            <ConfigPage />
        );
    }

}

export {
    ConfigPage,
    ConfigPageBuilder
};