/* TutorialSideBar.js
 * Description: The sidebar that is shown on the tutorial page
 * 
 * Props:
 *      entries
 *          list of entries that will be shown in the sidebar
 */
import React, { Component } from 'react';
import './TutorialSideBar.css';

class TutorialSideBar extends Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        let sideBarElems = this.props.entries.map((x) => {
            let entryClass = '';

            if (x.path === this.props.selectedEntry) {
                entryClass = 'selected';
            }

            return (
                <li className={`${entryClass}`} onClick={() => x.callback()}>
                    {x.title}
                </li>
            );
        });
        return (
            <div className="tutorial-side-bar">
                <ul>
                    {sideBarElems}
                </ul>
            </div>
        );
    }

}

export default TutorialSideBar;
