import React, {
    Component
} from 'react';
import './ProfilePage.css';

import { ErrorPage } from '../ErrorPage/ErrorPage';

import PageBuilder from '../../interfaces/PageBuilder.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import 'moment-timezone';

class ProfilePage extends Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            viewKey: false,
            key: this.props.profileInfo.api_key,
            keyChanged: this.props.profileInfo.key_changed
        }
    }

    async regenerateKey() {
        // // I believe this function is moot now that we use a single API key per app
        // const rawResponse = await fetch(`${window.apiUrl}/refresh_key`, {
        //     method: 'GET',

        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     }
        // });
        // try {
        //     const content = await rawResponse.json();

        //     // if we don't get what we want from the API call
        //     if (!(content) || !(content.new_key) || !(content.update_time)) {
        //         throw Error;
        //     }
        //     window.localStorage.apiKey = content.new_key;
        //     this.setState({
        //         key: content.new_key,
        //         keyChanged: content.update_time
        //     });
        //     document.getElementById('regen-loader').classList.remove('show');
        //     window.location = '/';
        // } catch (error) {
        //     document.getElementById('regen-loader').classList.remove('show');
        //     return;
        // }
        // return;


    }

    render() {
        return (
            <div className="profile-page-content">
                <div className="profile-page-inner">
                    <div className="profile-page-header">
                        <h1>Profile Information</h1>
                    </div>
                    <div className="profile-page-body">
                        <div className="profile-page-child">
                            <h3 className="profile-page-child-label">
                                Full Name
                            </h3>
                            <p className="profile-page-child-content">
                                {`${this.props.profileInfo.name}`}
                            </p>
                        </div>
                        <div className="profile-page-child">
                            <h3 className="profile-page-child-label">
                                Email Address
                            </h3>
                            <p className="profile-page-child-content">
                                {this.props.profileInfo.email}
                            </p>
                        </div>
                        <div className="profile-page-child">
                            <h3 className="profile-page-child-label">
                                Last Login
                            </h3>
                            <p className="profile-page-child-content">
                                <Moment local format="LLLL">
                                    {this.props.profileInfo.last_login}
                                </Moment>
                            </p>
                        </div>
                        <div className="profile-page-child">
                            <h3 className="profile-page-child-label">
                                Date Joined
                            </h3>
                            <p className="profile-page-child-content">
                                <Moment local format="LLLL">
                                    {this.props.profileInfo.date_joined}
                                </Moment>
                            </p>
                        </div>
                        <div className="profile-page-child">
                            {
                                this.props.profileInfo.admin ?
                                    <div className="profile-page-history unselectable">
                                        <a href="/admin">
                                            Admin Page
                                        </a>
                                    </div>
                                    :
                                    ''
                            }
                            {/* <div className="profile-page-history unselectable">
                                <a href="/history">
                                    View Search History
                                    <FontAwesomeIcon icon="history" className="sign-out-btn" />
                                </a>
                            </div> */}
                        </div>

                    </div>
                </div>
            </div>);
    }
}

class ProfilePageBuilder extends PageBuilder {

    // @override
    async onPageLoad() {
        const rawResponse = await fetch(`/get_profile_info`, {
            method: 'GET',

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        try {
            const content = await rawResponse.json();

            this.profileInfo = content;
            this.invalidProfile = false;
        } catch (error) {
            this.invalidProfile = true;
        }
        return;
    }

    // @override
    pageContent() {
        if (this.invalidProfile) {
            return <ErrorPage status={404} />;
        } else {
            return (
                <ProfilePage profileInfo={this.profileInfo} />
            );
        }
    }

}

export {
    ProfilePage,
    ProfilePageBuilder
};