import React, { Component } from 'react';
import Navbar from './components/Navbar/Navbar.js';

class Page extends Component {

    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <div className="page">
                <div className="header">
                    <Navbar />
                </div>
                <div className="content-section">
                    {this.props.content}
                </div>
            </div>
        );
    }

}

export default Page;
