// var apiUrl = process.env.API_URL || "api.imagingforgood.com";
var apiUrl = "api.ytaolf.com"

let configVars = {
    // "apiUrl": "http://159.203.118.35:80",
    "apiUrl": apiUrl,
    "hostedImagesUrl": "http://image-submissions.s3.amazonaws.com",
    "defaultMapCenter": [39.8293, -98.5795],
    "searchPageSize": 30,
    "hotelImgPageSize": 30,
    "maxSearchResults": 1000,
    "minimumPasswordLength": 8,
    "googleKey": 'AIzaSyAkOYm00c5s4en4JmyJ2-QDkCF4LBoJ7ZI'
}

let loadConfig = () => {
    for (let key in configVars) {
        window[key] = configVars[key];
        window.localStorage[key] = configVars[key];
    }
}

export { loadConfig };
