import React, {
    Component
} from 'react';
import './HistoryPage.css';

import PageBuilder from '../../interfaces/PageBuilder.js';
import HistoryPageEntry from '../../components/HistoryPageEntry/HistoryPageEntry.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class HistoryPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentPage: -1,
            // first page should always be 1
            firstPage: 1,
            lastPage: -1,
            totalResults: 0,
            entries: [],
            entriesLoaded: false,
            resultIndexStart: 0,
            resultIndexEnd: 0,
            initialResultsLoaded: false
        }
    }

    async loadEntries(nextPage) {
        // scroll to top of section to show new results
        let scrollSection = document.getElementById('history-page-scroll-section');

        // we need to make sure the element is accessible because this function
        // may be called before the element is rendered
        if (scrollSection) {
            scrollSection.scrollTop = 0;
        }


        // show loading
        let headerLoader = document.getElementById('header-loader');

        // smae principle as above
        if (headerLoader) {
            headerLoader.style.display = 'inline-block';
        }


        let passed = true;
        const searchResponse = await fetch(`/api/history?page=${nextPage}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).catch(() => {
            passed = false;
            this.setState({ entriesLoaded: true })
        });

        if (passed) {
            const content = await searchResponse.json();


            let results = content.history;

            let startIndex = ((nextPage - 1) * content.page_size);
            let endIndex = startIndex + results.length;

            // we do this because otherwise result indexes would overlap
            startIndex += 1;

            // make sure the element exists
            if (headerLoader) {
                headerLoader.style.display = 'none';
            }

            this.setState({
                entries: results,
                currentPage: nextPage,
                entriesLoaded: true,
                totalResults: content.total_length,
                lastPage: Math.ceil(content.total_length / content.page_size),
                resultIndexStart: startIndex,
                resultIndexEnd: endIndex,
                initialResultsLoaded: true
            });
        } else {
            // make sure the element exists
            if (headerLoader) {
                headerLoader.style.display = 'none';
            }
        }
        this.setState({ entriesLoaded: true });
        return;
    }

    mapEntries() {
        return this.state.entries.map((elem) => {
            return (
                <HistoryPageEntry searchID={elem.search_uuid}
                    imageUrl={elem.image_path} keywords={elem.keywords}
                    lat={elem.lat} lng={elem.lng} mapZoom={elem.map_zoom}
                    timestamp={elem.timestamp} />
            );
        });
    }

    componentDidUpdate() {
        // if there's only one page of history results
        if (this.state.lastPage === 1) {
            // hide everything except the current page
            // hide first
            document.getElementById('first-page').style.display = 'none';

            // hide left
            document.getElementById('previous-page').style.display = 'none';
            document.getElementById('left-seperator').style.display = 'none';

            // hide right
            document.getElementById('next-page').style.display = 'none';
            document.getElementById('right-seperator').style.display = 'none';

            // hide last
            document.getElementById('last-page').style.display = 'none';
        }
        // if on the first page
        else if (this.state.currentPage === 1) {
            // hide first
            document.getElementById('first-page').style.display = 'none';

            // hide left
            document.getElementById('previous-page').style.display = 'none';
            document.getElementById('left-seperator').style.display = 'none';

            // keep right
            document.getElementById('next-page').style.display = 'inline-block';

            //show last (if last isnt equal to next)
            if (this.state.currentPage + 1 === this.state.lastPage) {
                document.getElementById('last-page').style.display = 'none';
                document.getElementById('right-seperator').style.display = 'none';
            } else {
                document.getElementById('last-page').style.display = 'inline-block';
                // if the last page is only one larger than the next page
                // hide the seperator because it looks weird if we don't
                if (this.state.currentPage + 2 === this.state.lastPage) {
                    document.getElementById('right-seperator').style.display = 'none';
                } else {
                    document.getElementById('right-seperator').style.display = 'inline-block';
                }

            }

        }
        // if the current page is the last page
        else if (this.state.currentPage === this.state.lastPage) {
            // hide last
            document.getElementById('last-page').style.display = 'none';

            // hide next and right seperator
            document.getElementById('next-page').style.display = 'none';
            document.getElementById('right-seperator').style.display = 'none';

            // keep previous
            document.getElementById('previous-page').style.display = 'inline-block';

            // keep first unless it is the left
            if (this.state.firstPage === this.state.currentPage - 1) {
                document.getElementById('first-page').style.display = 'none';
                document.getElementById('left-seperator').style.display = 'none';
            } else {
                document.getElementById('first-page').style.display = 'inline-block';
                // if the first page is within one of the previous page hide
                // the seperator
                if (this.state.currentPage - 2 === this.state.firstPage) {
                    document.getElementById('left-seperator').style.display = 'none';
                } else {
                    document.getElementById('left-seperator').style.display = 'inline-block';
                }
            }
        }
        // EDGE CASE: if current page is sandwiched between and within one of
        // the first page and last page
        else if (this.state.currentPage - 1 === this.state.firstPage && this.state.currentPage + 1 === this.state.lastPage) {
            // show previous, and next;
            document.getElementById('previous-page').style.display = 'inline-block';
            document.getElementById('next-page').style.display = 'inline-block';

            // hide first page, last page, and seperators
            document.getElementById('first-page').style.display = 'none';
            document.getElementById('left-seperator').style.display = 'none';
            document.getElementById('right-seperator').style.display = 'none';
            document.getElementById('last-page').style.display = 'none';
        }

        // if current page is within one of first page
        else if (this.state.currentPage - 1 === this.state.firstPage) {
            // hide first page and left seperator
            document.getElementById('first-page').style.display = 'none';
            document.getElementById('left-seperator').style.display = 'none';

            // show everything else
            document.getElementById('previous-page').style.display = 'inline-block';
            document.getElementById('next-page').style.display = 'inline-block';
            document.getElementById('right-seperator').style.display = 'inline-block';
            document.getElementById('last-page').style.display = 'inline-block';
        }
        // if current page is within one of last page
        else if (this.state.currentPage + 1 === this.state.lastPage) {
            // hide last page and right seperator
            document.getElementById('last-page').style.display = 'none';
            document.getElementById('right-seperator').style.display = 'none';

            // show everything else
            document.getElementById('first-page').style.display = 'inline-block';
            document.getElementById('left-seperator').style.display = 'inline-block';
            document.getElementById('previous-page').style.display = 'inline-block';
            document.getElementById('next-page').style.display = 'inline-block';



        }

        // if current page is within two of first page
        else if (this.state.currentPage - 2 === this.state.firstPage) {
            // hide left seperator
            document.getElementById('left-seperator').style.display = 'none';

            // show everything else
            document.getElementById('first-page').style.display = 'inline-block';
            document.getElementById('previous-page').style.display = 'inline-block';
            document.getElementById('next-page').style.display = 'inline-block';
            document.getElementById('right-seperator').style.display = 'inline-block';
            document.getElementById('last-page').style.display = 'inline-block';
        }
        // if current page is within one of last page
        else if (this.state.currentPage + 2 === this.state.lastPage) {
            // hide right seperator
            document.getElementById('right-seperator').style.display = 'none';

            // show everything else
            document.getElementById('first-page').style.display = 'inline-block';
            document.getElementById('left-seperator').style.display = 'inline-block';
            document.getElementById('previous-page').style.display = 'inline-block';
            document.getElementById('next-page').style.display = 'inline-block';
            document.getElementById('last-page').style.display = 'inline-block';
        }
        // edge case
        else {
            // show everything
            document.getElementById('first-page').style.display = 'inline-block';
            document.getElementById('left-seperator').style.display = 'inline-block';
            document.getElementById('previous-page').style.display = 'inline-block';
            document.getElementById('next-page').style.display = 'inline-block';
            document.getElementById('right-seperator').style.display = 'inline-block';
            document.getElementById('last-page').style.display = 'inline-block';
        }
    }

    render() {
        let entries = this.mapEntries();

        if (!(this.state.entriesLoaded)) {
            this.loadEntries(1);
            entries = (
                <FontAwesomeIcon icon="circle-notch" className="fa-spin history-page-loader" />
            );
        } else {
            // if entries is empty
            if (!(entries.length)) {
                entries = "No search history found";
            }
        }



        return (
            <div className="history-page-content">
                <div className="history-page-inner">
                    <div className="history-page-header">
                        <h1>Search History</h1>
                        <FontAwesomeIcon icon="circle-notch" className="fa-spin" id="header-loader" />
                    </div>
                    <div className="history-page-scroll-section"
                        id="history-page-scroll-section"

                        style={{
                            height: window.innerHeight * .7
                        }}
                    >
                        <div className="history-page-body">
                            {entries}
                        </div>

                    </div>
                    <div className={`history-page-footer ${this.state.initialResultsLoaded && this.state.totalResults ? '' : 'hide'}`}>
                        <div className="history-page-footer-label">
                            Showing results {this.state.resultIndexStart} - {this.state.resultIndexEnd} out of {this.state.totalResults} results
                            </div>
                        <div className="history-page-footer-interaction">
                            <FontAwesomeIcon icon="chevron-left" className="footer-btn"
                                onClick={() => {
                                    // if we are already at the beginning, we
                                    // can't go any further left
                                    if (this.state.currentPage === 1) {
                                        return;
                                    }
                                    this.loadEntries(this.state.currentPage - 1);
                                }} />

                            <div className="history-page-number-line">
                                <span className="footer-interaction-child" id="first-page"
                                    onClick={() => {
                                        this.loadEntries(this.state.firstPage);
                                    }}
                                >
                                    {this.state.firstPage}
                                </span>
                                <span className="footer-interaction-child" id="left-seperator">
                                    ...
                                </span>
                                <span className="footer-interaction-child" id="previous-page"
                                    onClick={() => {
                                        this.loadEntries(this.state.currentPage - 1);
                                    }}
                                >
                                    {this.state.currentPage - 1}
                                </span>
                                <span className="footer-interaction-child" id="current-page">
                                    {this.state.currentPage}
                                </span>
                                <span className="footer-interaction-child" id="next-page"
                                    onClick={() => {
                                        this.loadEntries(this.state.currentPage + 1);
                                    }}>
                                    {this.state.currentPage + 1}
                                </span>
                                <span className="footer-interaction-child" id="right-seperator">
                                    ...
                                </span>
                                <span className="footer-interaction-child" id="last-page"
                                    onClick={() => {
                                        this.loadEntries(this.state.lastPage);
                                    }}>
                                    {this.state.lastPage}
                                </span>
                            </div>

                            <FontAwesomeIcon icon="chevron-right" className="footer-btn"
                                onClick={() => {
                                    // if we are already at the end, we can't go
                                    // any further right
                                    if (this.state.currentPage === this.state.lastPage) {
                                        return;
                                    }
                                    this.loadEntries(this.state.currentPage + 1);
                                }} />
                        </div>
                    </div>

                </div>
            </div>);
    }
}

class HistoryPageBuilder extends PageBuilder {

    // @override
    async onPageLoad() {
        return;
    }

    // @override
    pageContent() {
        return (
            <HistoryPage />
        );
    }

}

export {
    HistoryPage,
    HistoryPageBuilder
};