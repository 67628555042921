import React, { Component } from 'react';
import './InfoBubble.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

class InfoBubble extends Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            id: uuidv4()
        }
    }

    render() {
        return (
            <div className="info-bubble-wrapper">
                <p data-tip data-for={this.state.id}><FontAwesomeIcon icon="question-circle" /></p>
                <ReactTooltip id={this.state.id} place={this.props.direction}
                    className="tooltip"
                    effect="solid">
                    {this.props.text ? this.props.text : this.props.children}
                </ReactTooltip>
            </div>
        );
    }

    toggleInfo() {
        let bubble = document.getElementById(`bubble-${this.state.id}`);
        let textBox = document.getElementById(`text-${this.state.id}`);

        bubble.classList.toggle('show');
        textBox.classList.toggle('show');
        return;
    }
}

InfoBubble.defaultProps = {
    direction: 'bottom'
}

export default InfoBubble;