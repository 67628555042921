import React, {
    Component
} from 'react';
import './TutorialPage.css';

import PageBuilder from '../../interfaces/PageBuilder.js';

import TutorialSideBar from '../../components/TutorialSideBar/TutorialSideBar.js';
import SideBar from '../../components/SideBar/SideBar';

class TutorialPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 'search'
        }
    }

    render() {
        let contents = '';

        let sidebarEntries = [
            {
                title: 'Creating a new search',
                path: 'search',
                callback: () => { this.setState({ currentPage: 'search' }) }
            },
            {
                title: 'Analyzing results',
                path: 'results',
                callback: () => { this.setState({ currentPage: 'results' }) }
            },
            {
                title: 'Generating a report',
                path: 'report',
                callback: () => { this.setState({ currentPage: 'report' }) }
            },
            {
                title: 'Bulk uploading new images',
                path: 'upload',
                callback: () => { this.setState({ currentPage: 'upload' }) }
            }
        ];

        if (this.state.currentPage === 'search') {
            contents = (
                <div className="tutorialsection">
                    <div className="tutorialconent">
                        <h1>Creating a New Search</h1>
                        <div>
                            <p>From the main search page, you can upload a query image and specify additional parameters for the search.</p>
                            <img src="/img/search1.png" alt="Tutorial Image" className="tutorial-image"/>
                            <p>Using the menu on the left, you can restrict the search by <b>(1)</b> keywords or <b>(2)</b> location. Keywords can be simple (e.g., “Hilton”) or use the operators OR, AND, NOT (in CAPS) for more complex queries (e.g., “Hilton OR Hyatt”, “Holiday Inn NOT Suites”). For location-based searches, you can type a location (e.g., “Chicago”, “Rhode Island”)  into the text box and click “Update Map” or scroll and zoom the map</p>
                            <p>You can upload images by dragging and dropping onto the upload icon <b>(3)</b> or clicking the button <b>(4)</b> to open a file dialog. Once uploaded, there will be three menus for formatting the image: mask, edit, and filter.</p>
                            <h2>Mask</h2>
                            <img src="/img/search2.png" alt="Tutorial Image" className="tutorial-image"/>
                            <p>The mask tool can be used to “erase” regions of the query image containing people. If the image has been masked using another photo editing tool (outside of TraffickCam), you should reapply a (slightly larger) mask using this tool.</p>
                            <h2>Edit</h2>
                            <img src="/img/search3.png" alt="Tutorial Image" className="tutorial-image"/>
                            <p>The edit tool can be used to crop or rotate query images. If the image needs to be both edited and masked, the editing tool should be used first.</p>
                            <h2>Filter</h2>
                            <img src= "/img/search4.png" alt="Tutorial Image" className="tutorial-image"/>
                            <p>The filter tool can be used to make adjustments (brightness, contrast, saturation) to the appearance of the query image to correct any color balancing or lighting issues in the captured image.</p>
                        </div>
                    </div>
                </div>
            );
        }
        else if (this.state.currentPage === 'results') {
            contents = (
                <div className="tutorialsection">
                    <div className="tutorialconent">
                        <h1>Analyzing Results</h1>
                        <div>
                            <p>Once the (optionally masked and/or edited) image has been submitted, the search results page shows the closest matching images (with a match percentage) in the main part of the window.</p>
                            <img src="/img/results1.png" alt="Tutorial Image" className="tutorial-image" />
                            <ol type = "1">
                                <li>This shows the image used as a query. If you used the masking tool, you notice that the masked regions have been “inpainted”. Depending on the size of the masked region and the contents of the image, you may notice some streaking, blurring, or other image artifacts, which may or may not affect the search results.</li>
                                <li>The filtering tool allows you to refine the search results using *exact* text matching. This does not change the query; it just hides search results that do not match the text input.</li>
                                <li>You can rerun the search by updating the keywords or search location. If any of these values are changed, the “Update Search” button will appear.</li>
                                <li>To perform a new search, click “Reset Search”.</li>
                            </ol>
                            <br />
                            <br />
                            <p>You can get more information about a particular result by clicking on an image.</p>
                            <img src="/img/results2.png" alt="Tutorial Image" className="tutorial-image" />
                            <ol type= "1">
                                <li>This area shows other images captured at the same hotel.</li>
                                <li>The “Hotel Page” provides additional details about the hotel</li>
                                <li>The drop down menu provides a breakdown of the match score for searches that include both an image and keywords.</li>
                            </ol>
                        </div>
                    </div>
                </div>
            );
        }
        else if (this.state.currentPage === 'report') {
            contents = (
                <div className="tutorialsection">
                    <div className="tutorialconent">
                        <h1>Generating A Report</h1>
                        <div>
                            <p>As you are reviewing search results, you can select specific pictures to include in a summary report.</p>
                            <p>To include pictures in the report, click in the small circle in the upper right of the image. You can do this in either the primary search results <b>(1)</b>, or in the other example images from a hotel that show up when you click on an image <b>(2)</b>.</p>
                            <img src="/img/report1.png" alt="Tutorial Image" className="tutorial-image" />
                            <p>You will see a counter of the number of images currently included in the report, as well as an option to clear out the report <b>(3)</b>. Selecting Clear Report will de-select all images and start a new report.</p>
                            <p>When you have selected all of the images you wish to include in the report, select “Generate Search Report” <b>(4)</b>.</p>
                            <br />
                            <p>This will open a Report Preview screen:</p>
                            <img src="/img/report2.png" alt="Tutorial Image" className="tutorial-image" />
                            <p>In the Report Preview screen, you can see the images that you selected, organized by hotel <b>(1)</b>. If you wish to rearrange the hotel order (for example, to highlight a particular hotel at the top), you can use the drag and drop functionality under “Rearrange Hotel Order” <b>(2)</b> to re-order the hotels.</p>
                            <p>There is a text box for Notes <b>(3)</b>, where you can enter any notes that you think are important, such as why you included images or which hotels you think are the best matches.</p>
                            <p>The toggle buttons under Options <b>(4)</b> allow you to change which features are included in the report.</p>
                            <p>Once you are satisfied with the Report Preview, click “Generate Report” <b>(5)</b>.</p>
                            <br />
                            <p>This will open your computer’s print dialog (below):</p>
                            <img src="/img/report3.png" alt="Tutorial Image" className="tutorial-image" />
                            <p>Most systems have an option to Save as PDF instead of actually printing the output. You can save this output as a PDF to include the report in other systems as needed.</p>
                        </div>
                    </div>
                </div>
            );
        }
        else if (this.state.currentPage === 'upload') {
            contents = (
                <div className="tutorialsection">
                    <div className="tutorialconent">
                        <h1>Bulk Image Upload</h1>
                        <div>
                            <p>To upload hotel images from your browser, select the Upload button from the top right navigation bar (the navigation menu may be collapsed on smaller displays, as below).</p>
                            <img src="/img/upload1.png" alt="Tutorial Image" className="tutorial-image" />
                            <br />
                            <br />
                            <p>Find the hotel using the Google Maps search tool by typing its name. You can refine the search by first zooming the map to the hotel’s location.</p>
                            <img src="/img/upload2.png" alt="Tutorial Image" className="tutorial-image" />
                            <br />
                            <p>Once selected, the hotel information panel will display on the right side:</p>
                            <img src="/img/upload3.png" alt="Tutorial Image" className="tutorial-image" />
                            <br />
                            <br />
                            <p>Verify that the information is correct. If not, use the Google Maps search tool to select a new hotel. If the images were taken from the same hotel room, you can (optionally) add that information.</p>
                            <img src="/img/upload4.png" alt="Tutorial Image" className="tutorial-image" />
                            <p>Drag and drop the images from your computer into the bottom rectangle, or use the “Select Files” button to open a file selection dialog:</p>
                            <img src="/img/upload5.png" alt="Tutorial Image" className="tutorial-image" />
                            <br />
                            <br />
                            <p>When your images are ready to submit, you will see thumbnails and total image count, as shown below.</p>
                            <img src="/img/upload6.png" alt="Tutorial Image" className="tutorial-image" />
                            <p>You can add additional images or submit. You should receive a message after a successful upload. If you want to submit images (from a different hotel or room), click the link to refresh the page.</p>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="contents">
                <TutorialSideBar selectedEntry={this.state.currentPage} entries={sidebarEntries} />
                {contents}
            </div>
        );
    }

    //~ accountclick() {
        //~ this.setState({
            //~ currentPage: 'account'
        //~ });
    //~ }
    //~ imgclick() {
        //~ this.setState({
            //~ currentPage: 'upload'
        //~ });
    //~ }
    //~ searchclick() {
        //~ this.setState({
            //~ currentPage: 'search'
        //~ });
    //~ }
    //~ reportclick() {
        //~ this.setState({
            //~ currentPage: 'report'
        //~ });
    //~ }

}

class TutorialPageBuilder extends PageBuilder {

    // @override
    async onPageLoad() {
        return;
    }

    // @override
    pageContent() {
        return (
            <TutorialPage />
        );
    }

}

export {
    TutorialPage,
    TutorialPageBuilder
};
