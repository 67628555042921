import React, { Component } from 'react';

/**
 * All pages in /src/pages/ should have a second class at the bottom of their file which "implements"
 * (extends because this is JavaScript) PageBuilder. Doing so forces everyone to implement the methods below
 * @class PageBuilder
 */
class PageBuilder extends Component {

    /**
     * REQUIRED IMPLEMENTATION IN SUBCLASS
     * Callback when page loads. This is your chance to fetch data before constructing your page view
     * @param {Url} url url object (see https://www.npmjs.com/package/domurl)
     */
    onPageLoad(url) {
        throw new Error("Subclasses of PageBuilder must implement the async onPageLoad method")
    }

    /**
     * OPTIONAL IMPLEMENTATION IN SUBCLASS
     * Callback when page closes. Do cleanup actions, etc.
     * You should not rely on this method to save data because not all browsers call it all the time
     */
    onPageClose() { }

    /**
     * REQURIED IMPLEMENTATION IN SUBCLASS
     * Return a construced instance of your Page content component (Ex: SearchPage)
     * @returns {JSX} A constructed JSX component
     */
    pageContent() {
        throw new Error("Subclasses of PageBuilder must implement the pageContent method")
    }

}

export default PageBuilder;
