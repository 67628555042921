import React, { Component } from 'react';
import './ReportPageResult.css';

class ReportPageResult extends Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        let resultID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        return (
            <div className="report-preview-result">
                <img className="report-preview-img" src={this.props.imagePath}
                    id={`report-preview-img-${resultID}`}
                    alt="report preview result"
                    onError={(e) => {
                        console.dir(e.currentTarget);
                        e.currentTarget.classList.add('na')
                        e.currentTarget.nextElementSibling.classList.add('na');
                    }}

                />
                <div className="report-preview-error-text"
                    id={`report-preview-error-text-${resultID}`}
                >
                    Image Not Available
                </div>
                <div className="result-label">
                    <div className="result-label-inner">
                        {this.props.room ? `Room ${this.props.room}` : `No room specified`}
                    </div>
                </div>
            </div>
        );
    }

}

export default ReportPageResult;
