import React, {
    Component
} from 'react';
import './AdminLoginPage.css';

import PageBuilder from '../../interfaces/PageBuilder.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AdminLoginPage extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="admin-login-page-content">
                <div className="admin-login-page-wrapper">
                    <div className="admin-login-page-header">
                        <h1>Sign into TraffickCam Admin Page</h1>
                        <p>
                            Admins can use the form below to sign in. If you are not an admin, please login at <a href="/">our home page</a>.
                        </p>
                    </div>
                    <div className="admin-login-page-interaction">
                        <div className="admin-login-wrapper">
                            <input type="text" placeholder="&#61447;  Username" className="user-input"
                                id="user-input" />
                            <input type="password" placeholder="&#xf023;  Password" className="password-input"
                                id="password-input"
                                onKeyPress={(e) => {
                                    // if the key pressed was the enter key (signalling a submit)
                                    if (e.key.toLowerCase() === 'enter') {
                                        this.hideErrorMessages();
                                        this.showLoginLoading();
                                        this.sendData();
                                    }
                                }} />
                            <button className="btn-primary admin-login-btn"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.hideErrorMessages();
                                    this.showLoginLoading();
                                    this.sendData();
                                }}
                            >
                                Sign In
                                <FontAwesomeIcon icon="circle-notch" className="fa-spin" id="admin-login-loading" />
                            </button>
                            <div className="admin-login-error-msg">
                                <div id="invalid-creds">
                                    <FontAwesomeIcon icon="exclamation-circle" className="admin-error-icon" />
                                    Invalid credentials provided
                                </div>
                                <div id="server-unavailable">
                                    <FontAwesomeIcon icon="exclamation-triangle" className="admin-warning-icon" />
                                    Unable to reach server
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>);
    }

    showLoginLoading() {
        document.getElementById('admin-login-loading').classList.add('show');
    }

    hideLoginLoading() {
        document.getElementById('admin-login-loading').classList.remove('show');
    }

    hideErrorMessages() {
        document.getElementById('server-unavailable').classList.remove('show');
        document.getElementById('invalid-creds').classList.remove('show');
    }

    showInvalidCredentials() {
        document.getElementById('server-unavailable').classList.remove('show');
        document.getElementById('invalid-creds').classList.add('show');
    }

    showUnavailableServer() {
        document.getElementById('server-unavailable').classList.add('show');
        document.getElementById('invalid-creds').classList.remove('show');
    }

    async sendData() {
        let username = document.getElementById('user-input').value;
        let password = document.getElementById('password-input').value;

        let passed = true;
        const searchResponse = await fetch(`/admin_login_callback`, {
            method: 'POST',
            body: JSON.stringify({
                username: username,
                password: password
            }),

            headers: {
                'Content-Type': 'application/json'
            }
        }).catch(() => {
            passed = false;
        });

        if (passed) {
            try {
                const response = searchResponse;

                // if login was successful, redirect to admin page
                if (response.status === 200) {
                    window.history.pushState({}, 'admin', '/admin');
                    window.location = '/admin';
                } else {
                    this.hideLoginLoading();
                    this.showInvalidCredentials();
                }
            } catch{
                this.hideLoginLoading()
                this.showUnavailableServer();
            }
        } else {
            this.hideLoginLoading();
            this.showUnavailableServer();
        }
    }
}

class AdminLoginPageBuilder extends PageBuilder {

    // @override
    async onPageLoad() {
        return;
    }

    // @override
    pageContent() {
        return (
            <AdminLoginPage />
        );
    }

}

export {
    AdminLoginPage,
    AdminLoginPageBuilder
};
