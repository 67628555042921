import React, { useState, useCallback } from 'react';
import './DragDropSection.css';
import update from 'immutability-helper';
import DragDropHotel from '../DragDropHotel/DragDropHotel.js';

const Container = ({ hotelNames, updateHotelOrder }) => {
    {
        let pairs = hotelNames.map((x, i) => {
            return {
                id: i,
                text: x
            };
        })
        const [cards, setCards] = useState(pairs);

        const moveCard = useCallback(
            (dragIndex, hoverIndex) => {
                const dragCard = cards[dragIndex]
                setCards(
                    update(cards, {
                        $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
                    }),
                );
                let tempCards = cards.slice();

                tempCards.splice(dragIndex, 1);
                tempCards.splice(hoverIndex, 0, dragCard);
                updateHotelOrder(tempCards);
            },
            [cards],
        );

        const renderCard = (card, index) => {
            return (
                <DragDropHotel
                    key={card.id}
                    index={index}
                    id={card.id}
                    text={card.text}
                    moveCard={moveCard}
                />
            )
        }
        return (
            <>
                <div className="drag-drop-section">
                    {cards.map((card, i) => renderCard(card, i))}
                </div>
            </>
        )
    }
}
export default Container