import React, {Component} from 'react';

import './FixedSizeTable.css';

/* FixedSizeTable constructs a table out of `tableRows` (any[][])
 * with a header row `header` (any[]) of fixed size `height` (int; px) 
 * that scrolls through the list of rows.
 * Each entry in the arrays should have a unique 'id' and a component
 * to render in the table 'val'.
 * It is assumed that the rows of `tableRows` are all the same size;
 * the behavior of this class is not tested otherwise. */
class FixedSizeTable extends Component {
    constructor(props) {
        super(props);
    }

    // Convert any[] to an array of <th> elements
    headerArrayToElement(headerRow) {
        return headerRow.map((col) => {
            return (
                <th key={col.id}>{col.val}</th>
            );
        });
    }

    // Converty any[][] to an array of <tr> elements, each containing
    // several <td> elements.
    rowsArrayToElement(tableRows) {
        const entryToCell = (entry) => {
            return (
                <td key={entry.id}>{entry.val}</td>
            );
        }

        return tableRows.map((row, i) => {
            return (
                // Using index as a key is an anti-pattern, but since the
                // nested elements have proper keys, we are safe.
                <tr key={i} className='admin-tr-body'>
                    { row.map(entryToCell) }
                </tr>
            );
        });
    }

    render() {
        // List of columns for the header
        let header = this.headerArrayToElement(this.props.headerRow);

        // List of rows of columns for the body
        let rows = this.rowsArrayToElement(this.props.tableRows);

        return (
            <div className='admin-table-wrapper'
                    style={{maxHeight: this.props.height}}>
                <table className='admin-table'>
                    <thead>
                        <tr className='admin-tr-header'>
                            {header}
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default FixedSizeTable;