import React, { Component } from 'react';
import './HotelPageResultPanel.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class HotelPageResultPanel extends Component {

    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            hotelImages: [],
            mainImgRotation: 0
        }

    }

    async flagPanelImage(imageID) {
        imageID = imageID.split('-')[0];
        let encodedImageID = encodeURI(imageID);
        const rawResponse = await fetch(`/api/report_image/${encodedImageID}`, {
            method: 'GET',

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const content = await rawResponse.json();
    }

    render() {
        let panelSelector = document.getElementById(`hp-${this.props.id}-panel`);

        let panelShowing = false;
        if (panelSelector) {
            let panelClassList = [...panelSelector.classList];
            panelShowing = panelClassList.includes('show');
        }

        // Arrow Key listener for modal navigation
        // this could probably be one line of code if you feel like a lil challenge...
        if(panelShowing){
            let next = this.props.showNextResult;
            let prev = this.props.showPreviousResult;
            document.onkeydown = function() {
                switch (window.event.keyCode) {
                    case 39:
                        //right key
                        next();
                        break;

                    case 37:
                        //left key
                        prev();
                        break;
                };
            };
        };
        
        let rightButton = (
            <button className="hp-panel-switch right" onClick={() => { this.props.showNextResult() }}>
                <FontAwesomeIcon icon="arrow-right" />
            </button>);

        let leftButton = (
            <button className="hp-panel-switch left" onClick={() => { this.props.showPreviousResult() }}>
                <FontAwesomeIcon icon="arrow-left" />
            </button>);

        if (!(this.props.prevID)) {
            leftButton = '';
        } else if (!(this.props.nextID)) {
            rightButton = '';
        }

        let wrapperClass = 'hp-panel-main-image-wrapper';

        let mainImage = (
            <div className="hp-panel-inner-wrapper" id={`hp-panel-image-wrapper-${this.props.id}`}>
                <img src={this.props.imgUrl} className="hp-panel-main-image"
                    id={`panel-main-image-${this.props.id}`}
                    alt={this.props.hotelName}
                    onError={() => {
                        let imageSelector = document.getElementById(`panel-main-image-${this.props.id}`);
                        let errorSelector = document.getElementById(`panel-main-image-error-${this.props.id}`);
                        let rotateSelector = document.getElementById(`hp-rotate-panel-image-${this.props.id}`);


                        imageSelector.classList.toggle('na');
                        errorSelector.classList.toggle('na');
                        rotateSelector.classList.add('na');
                    }} />
                <div className="panel-main-image-error"
                    id={`panel-main-image-error-${this.props.id}`}
                >
                    Image Not Available

                </div>
                <div className="hp-flag-panel-image" id={`hp-flag-panel-image-${this.props.id}`}
                    onClick={() => {
                        let d = window.confirm('Reporting this image will remove it from future hotel results, are you sure you wish to proceed?');

                        if (d) {
                            this.flagPanelImage(this.props.id);
                            window.alert('The image has been reported and will be omitted from further hotel results.');
                        }

                    }}
                >
                    <FontAwesomeIcon icon="flag" className="hp-flag-panel-image-icon" />
                    Report image
                </div>
                <div className="hp-rotate-panel-image unselectable" id={`hp-rotate-panel-image-${this.props.id}`}
                    onClick={() => this.rotatePanelImage()}>
                    <FontAwesomeIcon icon="sync-alt" className="rotate-panel-image-icon" />
                </div>
            </div>
        );

        return (
            <div className="hp-result-panel" id={`hp-${this.props.id}-panel`}>
                <span className="hp-exit-panel"
                    onClick={() => { this.props.closeModal() }}
                >
                    X
                </span>
                <div className="hp-panel-left">
                    {leftButton}
                    <div className={wrapperClass}>
                        {mainImage}
                    </div>
                </div><div className="hp-panel-split"></div><div className="hp-panel-right">
                    <div className="hp-rps-wrapper">
                        {rightButton}
                    </div>
                    <div className="hp-panel-heading-wrapper">
                        <div className="hp-panel-heading">
                            {this.props.hotelName}
                        </div>
                        <div className="hp-panel-subheading">
                            {this.props.subText}
                        </div>

                        <a href={this.props.imgUrl}
                            className="hp-panel-hotel-link"
                            target="_blank"
                            rel="noopener noreferrer">
                            <FontAwesomeIcon icon="expand"
                                className="btn-info-label" />
                            View Full Size Image
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    rotatePanelImage() {
        let newRotation = this.state.mainImgRotation === 270 ? 0 : this.state.mainImgRotation + 90;



        let wrapperSelector = document.getElementById(`hp-panel-image-wrapper-${this.props.id}`);
        let maxHeight = wrapperSelector.clientHeight;
        let maxWidth = wrapperSelector.clientWidth;

        // alert(maxHeight);
        // alert(maxWidth);


        let panelImgSelector = document.getElementById(`panel-main-image-${this.props.id}`);
        let height = panelImgSelector.clientHeight;
        let width = panelImgSelector.clientWidth;

        let ogHeight = panelImgSelector.ogHeight ? panelImgSelector.ogHeight : height;
        let ogWidth = panelImgSelector.ogWidth ? panelImgSelector.ogWidth : width;

        panelImgSelector.ogHeight = ogHeight;
        panelImgSelector.ogWidth = ogWidth;




        panelImgSelector.style.transform = `rotate(${newRotation}deg)`;

        if (newRotation === 90 || newRotation === 270) {
            if (width > maxHeight) {
                panelImgSelector.style.width = `${maxHeight}px`;
            }
        } else {
            if (width > maxHeight) {
                panelImgSelector.style.width = `${maxHeight}px`;
            } else {
                panelImgSelector.style.width = `${ogWidth}px`;
            }
        }

        this.setState({ mainImgRotation: newRotation });
    }

    //~ nextPanel() {
        //~ if (!(this.props.nextID)) {
            //~ return;
        //~ }

        //~ let wrapperSelector = document.getElementById(`hp-result-wrapper-${this.props.nextID}`);
        //~ let panelSelector = document.getElementById(`hp-${this.props.nextID}-panel`);
        //~ let shifterSelector = document.getElementById(`${this.props.nextID}-shifter`);

        //~ let panels = document.getElementsByClassName('hp-result-panel');
        //~ let shifters = document.getElementsByClassName('page-shifter');
        //~ let wrappers = document.getElementsByClassName('hp-result-wrapper');

        //~ wrapperSelector.classList.toggle('focus');
        //~ panelSelector.classList.toggle('show');
        //~ shifterSelector.classList.toggle('show');

        //~ for (var i = 0; i < panels.length; i++) {
            //~ let item = panels.item(i);
            //~ if (item.id !== `hp-${this.props.nextID}-panel`) {
                //~ panels.item(i).classList.remove('show');
            //~ }
        //~ }

        //~ for (i = 0; i < shifters.length; i++) {
            //~ let item = shifters.item(i);
            //~ if (item.id !== `${this.props.nextID}-shifter`) {
                //~ shifters.item(i).classList.remove('show');
            //~ }
        //~ }

        //~ for (i = 0; i < wrappers.length; i++) {
            //~ let item = wrappers.item(i);
            //~ if (item.id !== `hp-result-wrapper-${this.props.nextID}`) {
                //~ wrappers.item(i).classList.remove('focus');
            //~ }
        //~ }

    //~ }

    //~ prevPanel() {
        //~ if (!(this.props.prevID)) {
            //~ return;
        //~ }
        //~ let wrapperSelector = document.getElementById(`hp-result-wrapper-${this.props.prevID}`);
        //~ let panelSelector = document.getElementById(`hp-${this.props.prevID}-panel`);
        //~ let shifterSelector = document.getElementById(`${this.props.prevID}-shifter`);

        //~ let panels = document.getElementsByClassName('hp-result-panel');
        //~ let shifters = document.getElementsByClassName('page-shifter');
        //~ let wrappers = document.getElementsByClassName('hp-result-wrapper');

        //~ for (var i = 0; i < panels.length; i++) {
            //~ let item = panels.item(i);
            //~ if (item.id !== `hp-${this.props.prevID}-panel`) {
                //~ panels.item(i).classList.remove('show');
            //~ }
        //~ }

        //~ for (i = 0; i < shifters.length; i++) {
            //~ let item = shifters.item(i);
            //~ if (item.id !== `${this.props.prevID}-shifter`) {
                //~ shifters.item(i).classList.remove('show');
            //~ }
        //~ }

        //~ for (i = 0; i < wrappers.length; i++) {
            //~ let item = wrappers.item(i);
            //~ if (item.id !== `hp-result-wrapper-${this.props.prevID}`) {
                //~ wrappers.item(i).classList.remove('focus');
            //~ }
        //~ }


        //~ wrapperSelector.classList.toggle('focus');
        //~ panelSelector.classList.toggle('show');
        //~ shifterSelector.classList.toggle('show');
    //~ }

}

export default HotelPageResultPanel;
