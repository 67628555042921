import React, { Component } from 'react';
import './ReportModal.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from 'react-switch';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import DragDropSection from '../DragDropSection/DragDropSection.js';
import ReportPageResult from '../ReportPageResult/ReportPageResult.js';

class ReportModal extends Component {

    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            coordinates: this.props.coordinates,
            mapZoom: this.props.mapZoom,
            reportResults: this.props.reportResults,
            reportResultsObjects: this.props.reportResultsObjects,
            hotelOrder: this.props.hotelOrder,
            imageChecked: true,
            locationChecked: true,
            keywordsChecked: true,
            notesChecked: true,
            searchInfo: this.props.searchInfo,
            sidebarImgUrl: this.props.sidebarImgUrl,
            location: this.props.location,
            keywords: this.props.keywords,
            reportNotesMax: this.props.reportNotesMax
        }
    }

    render() {
        let previewHeight = 450;
        let previewWidth = 348;
        if (window.innerHeight > 750) {
            previewHeight = 800;
            previewWidth = 618;
        } else if (window.innerHeight > 500) {
            previewHeight = 550;
            previewWidth = 425;
        }

        let mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${this.state.coordinates}&zoom=${this.state.mapZoom}&size=325x200&maptype=roadmap&key=${window.localStorage.googleKey}`;

        let reportImages = `No images added to report`;
        let hotelNames = [];
        if (this.state.reportResults.length) {
            reportImages = [];



            for (let i = 0; i < this.state.hotelOrder.length; i++) {
                let key = this.state.hotelOrder[i];
                let tempObjHolder = [];
                hotelNames.push(key);
                for (let obj in this.state.reportResultsObjects[key]) {

                    obj = this.state.reportResultsObjects[key][obj];

                    let reportPreviewImage = (
                        <ReportPageResult imagePath={obj.image_path}
                            room={obj.room} id={i} />
                    );

                    tempObjHolder.push((reportPreviewImage));
                }

                let reportImageElem = (
                    <div className= "report-hotel-section">
                        <h5>{key}</h5>
                        {tempObjHolder}
                    </div>

                );

                reportImages.push(reportImageElem);
            }
            reportImages = reportImages.map((x) => {
                return (x);
            });
        }

        return (
            <div className="report-gen">
                <div className="report-gen-contents">
                    <div className="report-preview-wrapper"
                        style={{ width: previewWidth }}>
                        <h1>Report Preview</h1>
                        <div className="report-preview" id="report-preview"
                            style={{
                                height: previewHeight,
                                width: previewWidth
                            }}
                        >
                            <div id= "print-media-wrapper">
                                <div className={`report-preview-params ${this.state.imageChecked || this.state.locationChecked || this.state.keywordsChecked}`}>
                                    <div className={`report-preview-params-child ${this.state.imageChecked}`}
                                        id="report-preview-params-img"
                                    >
                                        <h4>Uploaded Image</h4>
                                        <img src={this.state.searchInfo ? this.state.searchInfo.image_path : this.state.sidebarImgUrl}
                                            alt="uploaded search" />
                                    </div>

                                    <div className={`report-preview-params-child ${this.state.locationChecked}`}
                                        id="report-preview-params-location"
                                    >
                                        <h4>Location</h4>
                                        <p className="location-label">
                                            {
                                                this.state.location ?
                                                    this.state.location
                                                    :
                                                    `No location query set`
                                            }
                                        </p>
                                        <div id="report-map-wrapper">
                                            <img src={mapUrl} title="Report Map" id="report-map"></img>
                                        </div>
                                    </div>

                                    <div className={`report-preview-params-child ${this.state.keywordsChecked}`}
                                        id="report-preview-params-keywords"
                                    >
                                        <h4>Keywords</h4>
                                        <p>
                                            {
                                                this.state.keywords ?
                                                    this.state.keywords
                                                    :
                                                    `No keywords passed`
                                            }
                                        </p>
                                    </div>
                                </div>

                                <div className={`report-preview-notes ${this.state.notesChecked}`}>
                                    <h4>Notes</h4>
                                    <p id="report-notes-counter">
                                        {this.state.reportNotesMax} characters remaining
                                        </p>
                                    <textarea id="report-notes"
                                        placeholder="Enter notes here"
                                        maxLength={this.state.reportNotesMax}
                                        onChange={() => {
                                            let charCount = this.state.reportNotesMax - document.getElementById('report-notes').selectionStart;
                                            document.getElementById('report-notes-counter').innerText = `${charCount} characters remaining`
                                        }}>

                                    </textarea>
                                </div>

                                <div className="report-preview-images">
                                    <h4>Report Images</h4>
                                    <div className="report-preview-images-inner">
                                        {reportImages}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="report-interactions"
                        style={{
                            width: 400,
                        }}>
                        <div className="report-interactions-child">
                            <h2>
                                Rearrange Hotel Order
                                </h2>
                            <div className="report-rearrange">
                                {
                                    this.state.hotelOrder.length ?

                                        (
                                            <DndProvider backend={HTML5Backend}>
                                                <DragDropSection hotelNames={this.state.hotelOrder}
                                                    updateHotelOrder={(newOrder) => {
                                                        newOrder = newOrder.map((x) => {
                                                            return x.text;
                                                        });
                                                        this.setState({ hotelOrder: newOrder });
                                                    }} />
                                            </DndProvider>
                                        )

                                        :
                                        `No hotels to rearrange`
                                }

                            </div>
                        </div>
                        <div className="report-interactions-child">
                            <h2>
                                Options
                                </h2>

                            <div className="report-options-child">
                                <Switch onChange={() => {
                                    this.setState({
                                        imageChecked: !this.state.imageChecked
                                    })
                                }}
                                    checked={this.state.imageChecked}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onColor={`#5BB1DB`}
                                    offColor={`#BCBCBC`}
                                    height={20}
                                    width={40}
                                />
                                <p>Uploaded Image</p>
                            </div>

                            <div className="report-options-child">
                                <Switch onChange={() => {
                                    this.setState({
                                        locationChecked: !this.state.locationChecked
                                    })
                                }}
                                    checked={this.state.locationChecked}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onColor={`#5BB1DB`}
                                    offColor={`#BCBCBC`}
                                    height={20}
                                    width={40}
                                />
                                <p>Location</p>
                            </div>

                            <div className="report-options-child">
                                <Switch onChange={() => {
                                    this.setState({
                                        keywordsChecked: !this.state.keywordsChecked
                                    })
                                }}
                                    checked={this.state.keywordsChecked}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onColor={`#5BB1DB`}
                                    offColor={`#BCBCBC`}
                                    height={20}
                                    width={40}
                                />
                                <p>Keywords</p>
                            </div>

                            <div className="report-options-child">
                                <Switch onChange={() => {
                                    this.setState({
                                        notesChecked: !this.state.notesChecked
                                    })
                                }}
                                    checked={this.state.notesChecked}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onColor={`#5BB1DB`}
                                    offColor={`#BCBCBC`}
                                    height={20}
                                    width={40}
                                />
                                <p>Notes</p>
                            </div>
                        </div>
                        <div className="report-generate"
                            onClick={() => {
                                
                                let page = document.querySelector(".page");
                                let printMedia = document.getElementById("print-media-wrapper");
                                let report = document.getElementById("report-preview");
                                page.appendChild(printMedia);
                                setTimeout(function(){ window.print(); }, 500);
                                window.onafterprint = (event) => {
                                  report.appendChild(printMedia);
                                };
                                
                                
                                //~ window.print();
                            }}>
                            Generate Report
                                <FontAwesomeIcon icon="download"
                                id="gen-report-btn-label" />
                        </div>
                    </div>
                </div>
                <div className="report-gen-exit"
                    onClick={() => this.props.reportGenExit()}>
                    X
                    </div>
            </div>
        );
    }

}

export default ReportModal;
