import React, { Component } from 'react';
import './HotelPageSideBar.css';

import InfoBubble from '../../components/InfoBubble/InfoBubble';

class HotelPageSideBar extends Component {

    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            map: null
        };
    }

    componentDidUpdate() {
        let hotelCoords = JSON.parse(`[${this.props.hotelLocation}]`);

        let map = null;
        if (document.getElementById.bind('sidebar-map') && hotelCoords && !(this.state.map)) {
            try {
                map = new window.google.maps.Map(document.getElementById('sidebar-map'), {
                    center: { lat: parseFloat(hotelCoords[0]), lng: parseFloat(hotelCoords[1]) },
                    zoom: 10,
                    fullscreenControl: false,
                    streetViewControl: true
                });
                var marker = new window.google.maps.Marker({
                    position: { lat: parseFloat(hotelCoords[0]), lng: parseFloat(hotelCoords[1]) },
                    map: map,
                    title: this.props.hotelName,
                    animation: window.google.maps.Animation.DROP,
                });
            } catch (error) {
                document.getElementById('sidebar-map').innerText = 'Unable to load map';
            }

            this.setState({ map: map });

        }
    }

    render() {
        let query = this.props.hotelLocation.split(' ').join('+');
        query = encodeURI(query);

        // let subheader = (

        //     <div className="hpsb-subheader">
        //         {this.props.hotelPhoneNumber}
        //         ,<span className="space"></span>
        //         <a href={this.props.hotelSite} target="_blank"
        //             rel="noopener noreferrer">
        //             {this.props.hotelSite}
        //         </a>
        //     </div>
        // );

        let hotelPageText = `
            On this page, all relevant hotel information is displayed. All of
            the pictures on this page displayed are for the specific hotel
            selected. The pictures with the label "1st Party Submission" are
            images that were submitted by the hotel, whereas the other pictures
            are user submitted images`;

        return (
            <div className="hpsb" id="hpsb">
                <div className="hpsb-inner">
                    <div className="hpsb-header">
                        <span className="hpsb-header-text">{this.props.hotelName}</span>
                        <InfoBubble direction="right" text={hotelPageText} />
                    </div>
                    <div className="hpsb-hotel-info">
                        <div className="hpsb-address">{this.props.address}</div>
                        <div className="hpsb-website">{this.props.website}</div>
                        <div className="hpsb-phone">{this.props.phoneNumber}</div>
                    </div>
                    <br />
                    <div className="hpsb-map">
                        <div className="hotel-location" id="sidebar-map">

                        </div>
                    </div>
                    {
                        this.props.address ?
                            <div className="hpsb-info">
                                Address:
                                <span>{this.props.address}</span>
                            </div>
                            :
                            ''
                    }

                    {
                        this.props.phoneNumber ?
                            <div className="hpsb-info">
                                Phone Number:
                                <span>{this.props.phoneNumber}</span>
                            </div>
                            :
                            ''
                    }

                    {
                        this.props.website ?
                            <div className="hpsb-info">
                                Website:
                                <a href={this.props.website}>{this.props.website}</a>
                            </div>
                            :
                            ''
                    }
                    <div className="hpsb-img-count">
                        {this.props.imgCount} Total
                    {this.props.imgCount !== 1 ? ' Images' : ' Image'}
                    </div>
                </div>


            </div>
        );
    }

}

export default HotelPageSideBar;
