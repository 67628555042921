import React, { Component } from 'react';
import './HotelPageResult.css';

import HotelPageResultPanel from '../HotelPageResultPanel/HotelPageResultPanel.js';

class HotelPageResult extends Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        //let label = this.props.room ? 'Room ' + this.props.room : 'No room specified';

        var label = 'No date specified';
        if (this.props.date_uploaded) {
            try {
                let full_date = this.props.date_uploaded.split(" ");
                label = full_date[2] + " " + full_date[3]
            }
            catch {
                label = 'No date specified';
            }
        }

        //let label = this.props.date_uploaded ? this.props.date_uploaded : 'No date specified';

        let imageClasses = 'hp-result-img';
        //alert(this.props.id)

        return (
            <div className="hp-full-result-wrapper">
                <div className="hp-result-wrapper"
                    id={`hp-result-wrapper-${this.props.id}`}
                    onClick={() => { this.props.toggleModal(this.props.id); }}
                    title={label}>
                    <div className="hp-placeholder"
                        id={`hp-placeholder-${this.props.id}`}
                        style={{
                            backgroundRepeat: `no-repeat`,
                            backgroundPosition: `center`,
                            backgroundColor: `var(--lighter-grey)`
                        }}
                    >
                        <img className={imageClasses} id={`hp-${this.props.id}-result`}
                            alt={this.props.hotelName} onLoad={this.showImage.bind(this)}
                            src={this.props.imgUrl}
                            onError={() => {
                                let placeholderSelector = document.getElementById(`hp-placeholder-${this.props.id}`);
                                let imageSelector = document.getElementById(`hp-${this.props.id}-result`);
                                let errorSelector = document.getElementById(`hp-result-error-text-${this.props.id}`);

                                placeholderSelector.classList.toggle('na');
                                imageSelector.classList.toggle('na');
                                errorSelector.classList.toggle('na');
                            }} />
                        <div className="hp-result-error-text"
                            id={`hp-result-error-text-${this.props.id}`}
                        >
                            Image Not Available
                        </div>

                    </div>
                    <div className="hp-result-label">
                        <span className="hp-result-label-inner">
                            {label}
                        </span>
                    </div>
                </div>
                <HotelPageResultPanel
                    id={this.props.id}
                    hotelName={this.props.hotelName}
                    subText={label}
                    imgUrl={this.props.imgUrl}
                    prevID={this.props.prevID}
                    nextID={this.props.nextID}
                    key={this.props.id}
                    showNextResult={ () => this.props.toggleModal(this.props.nextID)}
                    showPreviousResult={ () => this.props.toggleModal(this.props.prevID)}
                    closeModal={ () => {
                        this.props.toggleModal(null);
                        }}
                />

                <div className="page-shifter" id={`${this.props.id}-shifter`}></div>
            </div>
        );
    }

    showImage() {
        let selector = document.getElementById('hp-' + this.props.id + '-result');

        if (this.props.imgUrl.startsWith(window.hostedImagesUrl)) {
            var imgHeight = window.getComputedStyle(selector).getPropertyValue('height');
            selector.parentElement.nextSibling.setAttribute('style', `max-width: ${imgWidth}`);
            selector.parentElement.setAttribute('style', `width: ${imgWidth}`);
        } else {
            var imgWidth = window.getComputedStyle(selector).getPropertyValue('width');
            selector.parentElement.nextSibling.setAttribute('style', `max-width: ${imgWidth}`);
        }

        selector.classList.toggle('show');
        selector.parentElement.classList.toggle('dynamic');
    }

    //~ async toggleAll() {
        //~ let panels = document.getElementsByClassName('hp-result-panel');
        //~ let shifters = document.getElementsByClassName('page-shifter');
        //~ let wrappers = document.getElementsByClassName('hp-result-wrapper');

        //~ for (var i = 0; i < panels.length; i++) {
            //~ let item = panels.item(i);
            //~ if (item.id !== `hp-${this.props.id}-panel`) {
                //~ panels.item(i).classList.remove('show');
            //~ }
        //~ }

        //~ for (i = 0; i < shifters.length; i++) {
            //~ let item = shifters.item(i);
            //~ if (item.id !== `${this.props.id}-shifter`) {
                //~ shifters.item(i).classList.remove('show');
            //~ }
        //~ }

        //~ for (i = 0; i < wrappers.length; i++) {
            //~ let item = wrappers.item(i);
            //~ if (item.id !== `hp-result-wrapper-${this.props.id}`) {
                //~ wrappers.item(i).classList.remove('focus');
            //~ }
        //~ }
    //~ }

    //~ showPanel() {
        //~ let wrapperSelector = document.getElementById(`hp-result-wrapper-${this.props.id}`);
        //~ let panelSelector = document.getElementById(`hp-${this.props.id}-panel`);
        //~ let shifterSelector = document.getElementById(`${this.props.id}-shifter`);

        //~ this.toggleAll();

        //~ wrapperSelector.classList.toggle('focus');
        //~ panelSelector.classList.toggle('show');
        //~ shifterSelector.classList.toggle('show');
    //~ }

}

export default HotelPageResult;
