import React, { Component } from 'react';
import './HistoryPageEntry.css';

import Moment from 'react-moment';
import 'moment-timezone';

class HistoryPageEntry extends Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        // load the map into the result
        let map = new window.google.maps.Map(document.getElementById(`entry-map-${this.props.searchID}`), {
            center: { lat: parseFloat(this.props.lat), lng: parseFloat(this.props.lng) },
            zoom: this.props.mapZoom - 1,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            scrollwheel: false,
            panControl: false,
            draggable: false
        });
    }

    componentDidUpdate() {
        // load the map into the result
        let map = new window.google.maps.Map(document.getElementById(`entry-map-${this.props.searchID}`), {
            center: { lat: parseFloat(this.props.lat), lng: parseFloat(this.props.lng) },
            zoom: this.props.mapZoom - 1,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            scrollwheel: false,
            panControl: false,
            draggable: false
        });
    }

    render() {
        return (
            <div className="history-entry">
                <div className="history-entry-img-wrapper">
                    <div className="history-entry-img-wrapper-inner">
                        <img src={this.props.imageUrl} className="history-entry-img" alt="search" />
                    </div>

                </div>
                <div className="history-entry-map-wrapper" id={`entry-map-${this.props.searchID}`}>
                </div>
                <div className="history-entry-info">
                    <a className="history-entry-info-child"
                        href={`/results/${this.props.searchID}`} target="_blank"
                        rel="noopener noreferrer"
                    >
                        Link to results
                    </a>
                    <div className="history-entry-info-child">
                        <span className="search-keywords-label">Keywords:</span>
                        <div className="search-keywords-value">
                            {this.props.keywords ? this.props.keywords : 'No Keywords'}
                        </div>
                    </div>

                    <div className="history-entry-info-child bottom">
                        <span className="search-time-value">
                            <Moment local format="LLLL">
                                {this.props.timestamp}
                            </Moment>
                        </span>
                    </div>

                </div>
            </div>
        );
    }

}

export default HistoryPageEntry;
